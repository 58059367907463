import React, { useEffect, useState } from 'react';
import { FaCheck, FaPlus, FaSave } from 'react-icons/fa';
import { HiOutlineRefresh } from 'react-icons/hi';
import { IoPrint } from 'react-icons/io5';
import { LuView } from 'react-icons/lu';
import { MdEdit } from 'react-icons/md';
import { RiCloseLargeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';
import axios from 'axios';


const MatirialReceipt = () => {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewForm, setShowViewForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const id = user ? user.id : null;

  const [selectedPOs, setSelectedPOs] = useState([]);


  const [poList, setPoList] = useState(['']);

  const [supplierList, setSupplierList] = useState([]); // Supplier data

  const [supplierFilter, setSupplierFilter] = useState(null);

  console.log(supplierList)

  useEffect(() => {
    const fetchPOData = async () => {
      try {
        // Fetch Purchase Orders
        const poResponse = await axios.get('https://erpapi.emedha.in/pages/api/poget/MatirialReceipt');
        setPoList(poResponse.data);


        const suppResponse = await axios.get('https://erpapi.emedha.in/pages/api/purSuppMst'); // Replace with your actual API endpoint  https://erpapi.emedha.in
        setSupplierList(suppResponse.data); // Assuming this API returns an array of suppliers
      } catch (error) {
        console.error("Error fetching purchase orders or suppliers", error);
      }
    };

    fetchPOData();
  }, []);

  const getSuppliername = (suppId) => {
    const supplier = supplierList.find(supplier => supplier.Supp_cd === suppId);
    return supplier ? supplier.Supp_Nm : "No Supplier";
  };


  const getSupplierName = (supp_id) => {
    const supplier = supplierList.find(s => s.Supp_cd === supp_id);
    return supplier ? supplier.Supp_Nm : 'Unknown Supplier'; // Return supplier name or 'Unknown Supplier' if not found
  };




  const [Supp_id, setSelectedSupplier] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [states, setStates] = useState({});
  const [stateMaster, setStateMaster] = useState({});
  // const [items, setItems] = useState('');
  const [Des_gst, setDesGst] = useState('');
  const [Des_state_cd, setDesState] = useState('');



  useEffect(() => {
    axios.get('https://erpapi.emedha.in/pages/api/api/suppliers')
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching suppliers:', error);
      });
    axios.get('https://erpapi.emedha.in/pages/api/api/states')
      .then((response) => {
        setStates(response.data);
        setStateMaster(response.data);
      })
      .catch((error) => {
        console.error('Error fetching state master:', error);
      });
    // axios.get('https://erpapi.emedha.in/pages/api/api/items-indents')
    //   .then((response) => {
    //     //  setItems(response.data)

    //     const filteredItems = response.data.filter(item => item.Flage === 1);

    //     setItems(filteredItems);
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching state master:', error);
    //   });
  }, []);

  const [stateName, setStateName] = useState('');
  const [stateCode, setStateCode] = useState('');

  useEffect(() => {
    // If we have a GST number and the states data is available, find the matching state
    if (selectedPOs.length > 0 && states) {
      const gst = selectedPOs[0].PO_HDR.Supp_gst;
      if (gst) {
        const stateCode = gst.slice(0, 2); // Get the first two characters of GST

        const state = states[stateCode]; // Get the state name using the state code
        setStateCode(stateCode);
        if (state) {
          setStateName(state); // Set the state name if found
        } else {
          setStateName('Unknown State'); // Handle if no state is found
        }
      }
    }
  }, [selectedPOs, states]);
  console.log(states); // Check the data structure of the state mappings
  console.log(stateCode)
  console.log(stateName)
  const supplier = suppliers.find(s => s.Supp_cd.toString() === Supp_id.toString());
  const extractStateFromGST = (gst) => {
    if (!gst) return '';
    const stateCode = gst.substring(0, 2);
    const stateName = states[stateCode] || 'Unknown State';
    return stateName;
  };

  const [desStateCode, setDesStateCode] = useState('')


  const handleDesGstChange = (e) => {
    const gstValue = e.target.value;
    if (gstValue.length <= 12) {
      setDesGst(gstValue);
      if (gstValue.length >= 2) {
        const stateCode = gstValue.substring(0, 2);
        setDesStateCode(stateCode)
        const stateName = stateMaster[stateCode] || 'Unknown State';
        setDesState(stateName);
      } else {
        setDesState('');
      }
    }
  };


  console.log('this is DEsc state code', desStateCode)
  console.log('this is Des state name', Des_state_cd)



  const [currentDate, setCurrentDate] = useState('');
  useEffect(() => {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  }, []);

  // const handleSave = async () => {
  //   console.log('Save functionality to be implemented');
  // };
  const [quantities, setQuantities] = useState({}); // Store quantities for each item

  // const handleInputChange = (itemCd, field, value) => {
  //   setQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     [itemCd]: {
  //       ...prevQuantities[itemCd],
  //       [field]: value,
  //     },
  //   }));
  // };

  const [formData, setFormData] = useState({
    Credit_Days: "",
    DC_Date: "",
    Dc_No: "",
    Due_Date: "",
    Invoice_Date: "",
    Invoice_No: "",

    Security_Date: "",
    Security_No: "",

  });
  const handleInputChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      MRN_No: e.target.value,
    }));
  };

  // const checkMRNExistence = async (mrnNo) => {
  //   try {
  //     const response = await fetch(`http://localhost:3007/pages/api/api/check-mrn/${mrnNo}`);
  //     const data = await response.json();
  //     return data.exists;
  //   } catch (error) {
  //     console.error('Error checking MRN:', error);
  //     return false;
  //   }
  // };




  const handleSave = async () => {
    // Step 1: Validate form data
    const isValid = Object.values(formData).every(value => value !== "");

    if (!isValid) {
      alert("Please fill in all required fields.");
      return;
    }

    // Step 2: Prepare MRN_HDR data (Header)
    const MRN_HDR = {

      Invoice_No: formData.Invoice_No,
      Dc_No: formData.Dc_No,
      Security_No: formData.Security_No,
      Des_GST: Des_gst,
      Des_State: desStateCode,
      MRN_Date: currentDate, // Use the state for current date
      Invoice_Date: formData.Invoice_Date,
      DC_Date: formData.DC_Date,
      Security_Date: formData.Security_Date,
      Supplier: selectedPOs.length > 0 ? selectedPOs[0].PO_HDR.Supp_id : "No Supplier",
      Credit_Days: formData.Credit_Days,
      Due_Date: formData.Due_Date,
      Supplier_GST: selectedPOs.length > 0 ? selectedPOs[0].PO_HDR.Supp_gst : "No Supplier",
      Supplier_State: stateCode,
    };

    // Step 3: Prepare MRN_DTL data (Details)
    const MRN_DTL = selectedPOs.map(po => po.PO_DTL.map(item => ({
      PO_No: po.PO_HDR.PO_no,
      Item_Code: item.itemCd,
      Item_Description: item.itemDesc,
      UOM: item.UOM,
      HSN_Code: item.Hsn_code,
      GST_Percent: item.GST_per,
      PO_Qty: item.PO_qty,
      Received_Qty: quantities[item.itemCd]?.Received_qty || '', // Get from state
      Accepted_Qty: quantities[item.itemCd]?.Accepted_qty || '', // Get from state
      Rejected_Qty: quantities[item.itemCd]?.Rejected_qty || '', // Get from state
    }))).flat();

    // Step 4: Combine header and details data for posting
    const dataToPost = {
      mrnHdrData: MRN_HDR,
      mrnDtlData: MRN_DTL
    };

    // Step 5: Post the data to the backend
    fetch('https://erpapi.emedha.in/pages/api/api/mrn', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataToPost),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Data successfully posted:', data);
        // Handle success, e.g., show success message or clear the form
        alert("Data successfully saved.");
        // Optionally reset the form and clear selected POs
        setFormData({
          Credit_Days: "",
          DC_Date: "",
          Dc_No: "",
          Due_Date: "",
          Invoice_Date: "",
          Invoice_No: "",
          MRN_No: "",
          Security_Date: "",
          Security_No: "",
        });
        setSelectedPOs([]);
        setQuantities({}); // Reset quantities if needed
      })
      .catch(error => {
        console.error('Error posting data:', error);
        alert("Error saving data. Please try again.");
      });
  };

  const handlePrint = () => {
    console.log('Print functionality');
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // const handlePOSelection = (po, checked) => {
  //   setSelectedPOs(prevSelected => {
  //     if (checked) {
  //       // Add PO to selected list
  //       return [...prevSelected, po];
  //     } else {
  //       // Remove PO from selected list
  //       return prevSelected.filter(selectedPo => selectedPo.PO_HDR.PO_no !== po.PO_HDR.PO_no);
  //     }
  //   });
  // };

  const handlePOSelection = (po, isChecked) => {
    if (isChecked) {
      // If the checkbox is checked, add the PO to the selectedPOs array
      setSelectedPOs(prevSelected => [...prevSelected, po]);
    } else {
      // If the checkbox is unchecked, remove the PO from the selectedPOs array
      setSelectedPOs(prevSelected => prevSelected.filter(selected => selected.PO_HDR.PO_no !== po.PO_HDR.PO_no));
    }
  };



  const handleConfirm = () => {
    console.log('Confirm MRN creation');
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // setSupplierFilter(null);
    // toggleModal();
    setSelectedPOs([]); // Clear selected POs
    setIsModalOpen(false); // Close modal
  };

  const handleApprove = (id) => {

  };
  // const handleRefresh = () => {

  // };


  const handleRefresh = () => {
    setSelectedPOs('');
    setStateName('');
    setStateCode("");
    setMRN_No("");

    setMrnHdrData("");
    setMrnDtlData("");
    setDesGst("");
    setDesState("");
    setFormData("");
    setStates("");
    setDesStateCode("");


  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const [formMode, setFormMode] = useState('');
  const userPermissions = user ? {
    add: user.add_permission,
    edit: user.edit_permission,
    view: user.view_permission,
    save: user.save_permission,
    approve: user.approve_permission,
    print: user.print_permission,
    close: user.close_permission,
    clear: user.clear_screen_permission
  } : {};

  const getButtonState = (permissionType) => {
    switch (formMode) {
      case 'create':
        return permissionType === 'create' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
      case 'edit':
        return permissionType === 'edit' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
      case 'view':
        return permissionType === 'view' || permissionType === 'clear' || permissionType === 'close';
      case 'approve':
        return permissionType === 'clear' || permissionType === 'view' || permissionType === 'approve' || permissionType === 'print' || permissionType === 'close';
      default:
        return false;
    }
  };

  const [MRN_No, setMRN_No] = useState(""); // Maintain MRN_No state

  // Fetch the latest MRN_No from the backend when the component mounts
  useEffect(() => {
    const fetchLatestMRN = async () => {
      try {
        const response = await fetch('https://erpapi.emedha.in/pages/api/api/get-latest-mrn');
        const data = await response.json();
        const latestMRN = data.latestMRN;

        // Set MRN_No to the next available value
        setMRN_No(latestMRN === 0 ? "1" : (parseInt(latestMRN) + 1).toString());
      } catch (error) {
        console.error('Error fetching latest MRN:', error);
      }
    };

    fetchLatestMRN();
  }, []);


  const [mrnNos, setMrnNos] = useState([]); // List of MRN Nos
  const [selectedMRNNo, setSelectedMRNNo] = useState(""); // Selected MRN No


  const [mrnHdrData, setMrnHdrData] = useState({}); // MRN Header data
  const [mrnDtlData, setMrnDtlData] = useState([]); // MRN Details data
  console.log(mrnHdrData)
  console.log(mrnDtlData)
  useEffect(() => {
    // Fetch the list of MRN Nos for the dropdown
    axios.get("https://erpapi.emedha.in/pages/api/mrn-nos").then((response) => {
      setMrnNos(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedMRNNo) {
      // Fetch MRN Header and Detail data when an MRN No is selected
      axios.get(`https://erpapi.emedha.in/pages/api/mrn/${selectedMRNNo}`).then((response) => {
        setMrnHdrData(response.data.mrnHdrData);
        setMrnDtlData(response.data.mrnDtlData);
      });
    }
  }, [selectedMRNNo]);

  const handleMRNChange = (e) => {
    setSelectedMRNNo(e.target.value);
  };


  //   const handleEditSubmit = () => {
  //     const editedData = {
  //         mrnHdrData: {
  //             MRN_No: selectedMRNNo,

  //             // Gather the edited header data here
  //         },
  //         mrnDtlData: rowsToDisplay.map(row => {
  //             return {
  //                 PO_No: row.PO_No,
  //                 Item_Code: row.Item_cd,
  //                 HSN_Code: row.Hsn_code,
  //                 GST_Percent: row.GST_Rate,
  //                 UOM: row.UOM,
  //                 PO_Qty: row.PO_qty,
  //                 Received_Qty: row.Received_qty,
  //                 Accepted_Qty: row.Accepted_qty,
  //                 Rejected_Qty: row.Rejected_qty,
  //             };
  //         }),
  //     };

  //     fetch(`http://localhost:3007/pages/api/api/mrn/${selectedMRNNo}`, {
  //         method: 'PUT',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(editedData),
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //         console.log('Success:', data);
  //         // Handle success (maybe show a success message)
  //     })
  //     .catch((error) => {
  //         console.error('Error:', error);
  //         // Handle error (show error message)
  //     });
  // };
  const handleEditSubmit = async () => {
    try {
      // Send PUT request to update MRN data
      const response = await axios.put(
        `https://erpapi.emedha.in/pages/api/mrn/${selectedMRNNo}`,
        {
          mrnHdrData,
          mrnDtlData
        }
      );

      // Handle success response
      alert(response.data.message);  // Display success message
    } catch (error) {
      console.error("Error updating MRN:", error);
      alert("Failed to update MRN data.");
    }
  };


  // const handleEditSubmit  = async (e) => {
  //   e.preventDefault();

  //   const updatedMRN = {
  //       mrnHdrData,
  //       mrnDtlData,
  //   };

  //   try {
  //       const response = await axios.put(`http://localhost:3007/pages/api/api/mrn/${selectedMRNNo}`, updatedMRN);
  //       alert('MRN updated successfully!');
  //   } catch (error) {
  //       console.error('Error updating MRN:', error);
  //       alert('Failed to update MRN');
  //   }
  // };









  const rowsToDisplay = mrnDtlData.length >= 4 ? mrnDtlData : [...mrnDtlData, ...Array(4 - mrnDtlData.length).fill({})];

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if single digit
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  //   const year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };
  function formatDateToDateInput(date) {
    // Convert date string to a Date object
    const dateObj = new Date(date);

    // Adjust for timezone offset and convert to 'YYYY-MM-DD' format
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = dateObj.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <div style={{ margin: '0 auto', backgroundColor: '#dbc4a9', padding: '1rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid black', borderRadius: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={A1} alt="item" style={{ width: "20%" }} />
          <h1 style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>MATERIAL RECEIPT</h1>
          <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed",
              opacity: userPermissions.add && userPermissions.add !== 0 && getButtonState('create') ? 1 : 0.5
            }}>
              <FaPlus size={30} onClick={() => {
                if (userPermissions.add && userPermissions.add !== 0) {
                  toggleModal();
                  setFormMode('create');
                  setShowCreateForm(true);
                  setShowViewForm(false);
                  setShowEditForm(false);
                }
              }} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed",
              opacity: userPermissions.edit && userPermissions.edit !== 0 && getButtonState('edit') ? 1 : 0.5
            }}>
              <MdEdit size={30} onClick={() => {
                if (userPermissions.edit && userPermissions.edit !== 0) {
                  setShowCreateForm(false);
                  setFormMode('edit');
                  setShowViewForm(false);
                  setShowEditForm(true);
                } else {
                  alert("You do not have permission to edit.");
                }
              }} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed",
              opacity: userPermissions.view && userPermissions.view !== 0 && getButtonState('view') ? 1 : 0.5
            }}>
              <LuView size={30} onClick={() => {
                if (userPermissions.view && userPermissions.view !== 0) {
                  setShowCreateForm(false);
                  setShowViewForm(true);
                  setShowEditForm(false);
                  setFormMode('view');
                } else {
                  alert("You do not have permission to view.");
                }
              }} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? "pointer" : "not-allowed",
              opacity: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? 1 : 0.5
            }}>
              <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
            </div>

            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? "pointer" : "not-allowed",
              opacity: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? 1 : 0.5
            }}>
              <FaSave size={30} onClick={() => {
                if (userPermissions.save && userPermissions.save !== 0) {
                  if (formMode === 'create') {
                    handleSave(); // If formMode is approve, use handleApprove
                  } else if (formMode === 'edit') {
                    handleEditSubmit(); // If formMode is create or edit, use handleSave
                  }
                } else {
                  alert("You do not have permission to save.");
                }
              }}
              />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? "pointer" : "not-allowed",
              opacity: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? 1 : 0.5
            }}>
              <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? handleApprove : null} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? "pointer" : "not-allowed",
              opacity: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? 1 : 0.5
            }} title='Print'>
              <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? "pointer" : "not-allowed",
              opacity: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? 1 : 0.5
            }} title='Exit'>
              <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
            </div>
          </div>
        </div>
        <hr />


        {showCreateForm && (
          <>
            {isModalOpen && (
              <div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ backgroundColor: '#dbc4a9', padding: '16px', borderRadius: '8px', width: '80%' }}>
                  <h4 className='text-center'>PENDING PURCHASE ORDERS</h4>
                  <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>PO</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier CD</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier Name</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Desc</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Quantity</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Selection</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                {poList.filter(po => !supplierFilter || po.PO_HDR.supplierCd === supplierFilter).map(po => (
                    po.PO_DTL.map(item => (
                      <tr key={item.itemCd}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{po.PO_HDR.PO_no}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{po.PO_HDR.Supp_id}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                          {getSupplierName(po.PO_HDR.Supp_id)} 
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.itemCd}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.itemDesc}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.UOM}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.qty}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                          <input
                            type="checkbox"
                            onChange={(e) => handlePOSelection(po, e.target.checked)}
                          />
                        </td>
                      </tr>
                    ))
                  ))
                }
              </tbody> */}
                    <tbody>
                      {
                        // Ensure poList is defined and filter for valid items
                        poList && poList.length > 0 && poList.filter(po => !supplierFilter || po.PO_HDR.supplierCd === supplierFilter).map(po => (
                          // Ensure PO_DTL is an array before calling map
                          po.PO_DTL && Array.isArray(po.PO_DTL) && po.PO_DTL.map(item => (
                            <tr key={item.itemCd}>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{po.PO_HDR.PO_no}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{po.PO_HDR.Supp_id}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>
                                {getSupplierName(po.PO_HDR.Supp_id)} {/* Get supplier name based on Supp_id */}
                              </td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{item.itemCd}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{item.itemDesc}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{item.UOM}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{item.qty}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>
                                <input
                                  type="checkbox"
                                  onChange={(e) => handlePOSelection(po, e.target.checked)}
                                />
                              </td>
                            </tr>
                          ))
                        ))
                      }
                    </tbody>

                  </table>
                  <div style={{ marginTop: '16px' }}>
                    <button style={{ backgroundColor: "blue", color: 'white', padding: '2px 20px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} onClick={handleConfirm}>Confirm</button>
                    <button style={{ backgroundColor: "blue", color: 'white', padding: '2px 20px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} onClick={handleCancel}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
            {selectedPOs.length > 0 && (
              <div>
                <div className='p-1' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '83px', color: 'darkblue' }}>MRN No:</label>
                      <input
                        readOnly
                        value={MRN_No}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '69px', color: 'darkblue' }}>Invoice No:</label>
                      <input maxLength={12} pattern="[A-Za-z0-9]+" name='type'
                        type='text'
                        value={formData.Invoice_No}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Invoice_No: e.target.value,
                        }))}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '100px', color: 'darkblue' }}>Dc No:</label>
                      <input maxLength={12}
                        pattern="[A-Za-z0-9]+"
                        name='type' type='text'
                        value={formData.Dc_No}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Dc_No: e.target.value,
                        }))}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '63px', color: 'darkblue' }}>Security No:</label>
                      <input
                        type='text'
                        maxLength={6}
                        value={formData.Security_No}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Security_No: e.target.value,
                        }))}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '32px', color: 'darkblue' }}>Destination GST:</label>
                      <input maxLength={12} type="text" name="Des_gst" value={Des_gst} onChange={handleDesGstChange} pattern="[A-Za-z0-9]+" style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '24px', color: 'darkblue' }}>Destination State:</label>
                      <input type="text" name="Des_state_cd" value={Des_state_cd} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                  </div>

                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '40px', color: 'darkblue' }}>MRN Dt:</label>
                      <input
                        type='date'
                        value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '26px', color: 'darkblue' }}>Invoice Dt:</label>
                      <input
                        type='date'
                        value={formData.Invoice_Date}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Invoice_Date: e.target.value,
                        }))}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '56px', color: 'darkblue' }}>DC Dt:</label>
                      <input
                        type='date'
                        value={formData.DC_Date}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          DC_Date: e.target.value,
                        }))}
                        pattern="[A-Za-z0-9]+" style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '21px', color: 'darkblue' }}>Security Dt:</label>
                      <input
                        type='date'
                        value={formData.Security_Date}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Security_Date: e.target.value,
                        }))}
                        pattern="[A-Za-z0-9]+" style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                  </div>

                  <div>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                  <select style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} >
                    <option value="">Select</option>   Des_gst
                  </select>
                </div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                      <span
                        style={{
                          border: 'none',
                          borderLeft: '2px solid #D1D5DB',
                          padding: '10px',
                          width: '50%',
                          outline: 'none',
                          backgroundColor: 'transparent',
                          display: 'inline-block',
                        }}
                      >
                        {selectedPOs.length > 0
                          ? getSuppliername(selectedPOs[0].PO_HDR.Supp_id)
                          : "No Supplier"}

                      </span>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '22px', color: 'darkblue' }}>Credit Days:</label>
                      <input
                        type="text"
                        maxLength={4}
                        value={formData.Credit_Days}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Credit_Days: e.target.value,
                        }))}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '54px', color: 'darkblue' }}>Due Dt:</label>
                      <input
                        type='date'
                        value={formData.Due_Date}
                        onChange={(e) => setFormData((prevData) => ({
                          ...prevData,
                          Due_Date: e.target.value,
                        }))}
                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '13px', color: 'darkblue' }}>Supplier GST:</label>
                  <input style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ marginRight: '14px', color: 'darkblue' }}>Supplier GST:</label>
                      <span
                        maxLength={12}
                        style={{
                          border: 'none',
                          borderLeft: '2px solid #D1D5DB',
                          padding: '10px',
                          width: '50%',
                          outline: 'none',
                          backgroundColor: 'transparent',
                          display: 'inline-block',
                        }}
                      >
                        {selectedPOs.length > 0 ? selectedPOs[0].PO_HDR.Supp_gst : "No Supplier"}
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                      <input type="text" name="stateName" value={stateName} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                  <input type="text" name="Supp_state_cd" value={supplier ? extractStateFromGST(supplier.Gst_No) : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div> */}
                  </div>
                </div>
                <div style={{ overflowY: 'auto', maxHeight: '240px' }}>
                  <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SI.No</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Po No</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>HSN CODE</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>GST %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>PO Qty</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Received Qty</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Accepted Qty</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Rejected Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPOs.map((po, index) => (po.PO_DTL.map(item => (
                        <tr key={item.itemCd}>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{po.PO_HDR.PO_no}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item.itemCd}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item.itemDesc}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item.UOM}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item.Hsn_code}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item.GST_per}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item.PO_qty}</td>
                          {/*<td style={{ border: '1px solid black', padding: '8px' }}><input type="text" name='' style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                        <td style={{ border: '1px solid black', padding: '8px' }}><input type="text" name='' style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                        <td style={{ border: '1px solid black', padding: '8px' }}><input type="text" name='' style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" /></td> */}
                          <td style={{ border: '1px solid black', padding: '8px' }}>
                            <input
                              type="text"
                              value={quantities[item.itemCd]?.Received_qty || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                // Check if a decimal is entered and ensure only two digits after the decimal
                                if (inputValue.includes('.')) {
                                  let parts = inputValue.split('.');
                                  if (parts[1] && parts[1].length > 2) {
                                    // Limit the digits to 2 after the decimal
                                    e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                                  }
                                }



                                setQuantities((prevQuantities) => ({
                                  ...prevQuantities,
                                  [item.itemCd]: {
                                    ...prevQuantities[item.itemCd],
                                    Received_qty: e.target.value,
                                  },
                                }))
                              }}
                              style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }}
                              maxLength={10}
                            />
                          </td>
                          {/* Accepted Qty input */}
                          <td style={{ border: '1px solid black', padding: '8px' }}>
                            <input
                              type="text"
                              value={quantities[item.itemCd]?.Accepted_qty || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                // Check if a decimal is entered and ensure only two digits after the decimal
                                if (inputValue.includes('.')) {
                                  let parts = inputValue.split('.');
                                  if (parts[1] && parts[1].length > 2) {
                                    // Limit the digits to 2 after the decimal
                                    e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                                  }
                                }
                                setQuantities((prevQuantities) => ({
                                  ...prevQuantities,
                                  [item.itemCd]: {
                                    ...prevQuantities[item.itemCd],
                                    Accepted_qty: e.target.value,
                                  },
                                }))
                              }}
                              style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }}
                              maxLength={10}
                            />
                          </td>
                          {/* Rejected Qty input */}
                          <td style={{ border: '1px solid black', padding: '8px' }}>
                            <input
                              type="text"
                              value={quantities[item.itemCd]?.Rejected_qty || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                // Check if a decimal is entered and ensure only two digits after the decimal
                                if (inputValue.includes('.')) {
                                  let parts = inputValue.split('.');
                                  if (parts[1] && parts[1].length > 2) {
                                    // Limit the digits to 2 after the decimal
                                    e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                                  }
                                }
                                setQuantities((prevQuantities) => ({
                                  ...prevQuantities,
                                  [item.itemCd]: {
                                    ...prevQuantities[item.itemCd],
                                    Rejected_qty: e.target.value,
                                  },
                                }))
                              }}
                              style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }}
                              maxLength={10}
                            />
                          </td>
                        </tr>
                      ))
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>

        )}


        {showViewForm && (

          <div>
            <div className="p-1" style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "83px", color: "darkblue" }}>MRN No:</label>
                  <select
                    value={selectedMRNNo}
                    onChange={handleMRNChange}
                    style={{ border: "none", borderLeft: "2px solid #D1D5DB", padding: "10px", width: "50%", outline: "none", backgroundColor: "transparent" }}
                  >
                    <option value="">Select MRN No</option>
                    {mrnNos.map((mrn) => (
                      <option key={mrn} value={mrn}>
                        {mrn}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "69px", color: "darkblue" }}>Invoice No:</label>
                  <input
                    type="text"
                    value={mrnHdrData.Invoice_no}
                    readOnly
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "100px", color: "darkblue" }}>Dc No:</label>
                  <input
                    type="text"
                    value={mrnHdrData.Dc_no || ""}
                    readOnly
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "63px", color: "darkblue" }}>Security No:</label>
                  <input
                    type="text"
                    value={mrnHdrData.Security_no || ""}
                    readOnly
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '32px', color: 'darkblue' }}>Destination GST:</label>
                  <input maxLength={12} type="text" name="Des_gst" value={mrnHdrData.Des_gst} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>


                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '24px', color: 'darkblue' }}>Destination State:</label>
                  <input type="text" name="Des_state_cd" value={mrnHdrData.Des_state_cd} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>




              </div>

              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '40px', color: 'darkblue' }}>MRN Dt:</label>
                  <input
                   value={
                    mrnHdrData.MRN_Dt
                      ? formatDateToDateInput(mrnHdrData.MRN_Dt) // Format the date for the input field
                      : '' // If there's no date, show an empty input
                  }
                    readOnly
                    style={{
                      border: 'none',
                      borderLeft: '2px solid #D1D5DB',
                      padding: '10px', width: '50%',
                      outline: 'none', backgroundColor: 'transparent'
                    }} />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "10px", color: "darkblue" }}>Invoice Date:</label>
                  <input
                    // value={mrnHdrData.Invoice_dt ? new Date(mrnHdrData.Invoice_dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    value={
                      mrnHdrData.Invoice_dt
                        ? formatDateToDateInput(mrnHdrData.Invoice_dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    readOnly
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '56px', color: 'darkblue' }}>DC Dt:</label>
                  <input
                    readOnly
                    // value={mrnHdrData.DC_Dt}
                   // value={mrnHdrData.DC_Dt ? new Date(mrnHdrData.DC_Dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                   value={
                    mrnHdrData.DC_Dt
                      ? formatDateToDateInput(mrnHdrData.DC_Dt) // Format the date for the input field
                      : '' // If there's no date, show an empty input
                  }
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '21px', color: 'darkblue' }}>Security Dt:</label>
                  <input
                    //value={mrnHdrData.Security_dt ? new Date(mrnHdrData.Security_dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    value={
                      mrnHdrData.Security_dt
                        ? formatDateToDateInput(mrnHdrData.Security_dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    // value={mrnHdrData.Security_dt}
                    readOnly
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
              </div>

              <div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                  <select style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} >
                    <option value="">Select</option>   Des_gst
                  </select>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                  <input
                    // value={mrnHdrData.Supplier_cd }
                    value={
                      supplierList.find(supplier => supplier.Supp_cd === mrnHdrData.Supplier_cd)?.Supp_Nm || ''
                    }
                    style={{
                      border: 'none',
                      borderLeft: '2px solid #D1D5DB',
                      padding: '10px',
                      width: '50%',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      display: 'inline-block',
                    }}
                  >

                  </input>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '22px', color: 'darkblue' }}>Credit Days:</label>
                  <input

                    maxLength={4}
                    value={mrnHdrData.Credit_days
                    }

                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '54px', color: 'darkblue' }}>Due Dt:</label>
                  <input

                    // value={mrnHdrData.Due_dt}
                    value={
                      mrnHdrData.Due_dt 
                        ? formatDateToDateInput(mrnHdrData.Due_dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    //value={mrnHdrData.Due_dt ? new Date(mrnHdrData.Due_dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '13px', color: 'darkblue' }}>Supplier GST:</label>
                  <input style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '14px', color: 'darkblue' }}>Supplier GST:</label>
                  <input
                    value={mrnHdrData.Supp_gst}
                    maxLength={12}
                    style={{
                      border: 'none',
                      borderLeft: '2px solid #D1D5DB',
                      padding: '10px',
                      width: '50%',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      display: 'inline-block',
                    }}
                  >

                  </input>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                  <input type="text" value={mrnHdrData.Supp_state_cd}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                  <input type="text" name="Supp_state_cd" value={supplier ? extractStateFromGST(supplier.Gst_No) : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div> */}
              </div>
            </div>

            <div style={{ overflowY: "auto", maxHeight: "240px" }}>
              <table style={{ minWidth: "100%", borderCollapse: "collapse", border: "1px solid black" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>SI.No</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>PO No</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>Item Code</th>
                    {/* <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>Item Description</th> */}
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>UOM</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>HSN CODE</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>GST %</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>PO Qty</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue", width: '230px' }}>Received Qty</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue", width: '230px' }}>Accepted Qty</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue", width: '230px' }}>Rejected Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {mrnDtlData.map((item, index) => ( */}
                  {/* {(mrnDtlData.length > 0 ? mrnDtlData : Array(4).fill({})).map((item, index) => ( */}
                  {rowsToDisplay.map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{index + 1}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.PO_No}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Item_cd}</td>
                      {/* <td style={{ border: "1px solid black", padding: "8px" }}>{item.itemDesc}</td> */}
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.UOM}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Hsn_code}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.GST_Rate}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.PO_qty}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Received_qty}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Accepted_qty}</td>
                      <td style={{ border: "1px solid black", padding: "8px", }}>{item.Rejected_qty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


        )}

        {showEditForm && (

          <div>
            <div className="p-1" style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
              <div>
                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "79px", color: "darkblue" }}>MRN No :</label>
                  <select
                    value={selectedMRNNo}
                    onChange={handleMRNChange}
                    style={{ border: "none", borderLeft: "2px solid #D1D5DB", padding: "10px", width: "50%", outline: "none", backgroundColor: "transparent" }}
                  >
                    <option value="">Select MRN No</option>
                    {mrnNos.map((mrn) => (
                      <option key={mrn} value={mrn}>
                        {mrn}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div style={{ display: "flex", alignItems: "center" }}>
  <label style={{ marginRight: "79px", color: "darkblue" }}>MRN No :</label>
  <select
    value={selectedMRNNo}
    onChange={handleMRNChange}
    style={{
      border: "none",
      borderLeft: "2px solid #D1D5DB",
      padding: "10px",
      width: "50%",
      outline: "none",
      backgroundColor: "transparent",
      height: "40px", // default height for the select box
      maxHeight: "12  0px", // set max height for the dropdown when expanded
      overflowY: "auto", // enables scrolling if content exceeds max height
      lineHeight: "1.5", // controls the line height of options
    }}
  >
    <option value="">Select MRN No</option>
    {mrnNos.map((mrn) => (
      <option key={mrn} value={mrn} style={{ padding: "8px" }}>
        {mrn}
      </option>
    ))}
  </select>
</div>


                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "69px", color: "darkblue" }}>Invoice No:</label>
                  <input
                    type="text"
                    value={mrnHdrData.Invoice_no}
                    onChange={(e) => {
                      setMrnHdrData({ ...mrnHdrData, Invoice_no: e.target.value });
                    }}

                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "100px", color: "darkblue" }}>Dc No:</label>
                  <input
                    type="text"
                    value={mrnHdrData.Dc_no || ""}
                    onChange={(e) => {
                      // Update the Invoice_no value based on input
                      setMrnHdrData({ ...mrnHdrData, Dc_no: e.target.value });
                    }}
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "63px", color: "darkblue" }}>Security No:</label>
                  <input
                    type="text"
                    value={mrnHdrData.Security_no || ""}
                    onChange={(e) => {
                      // Update the Invoice_no value based on input
                      setMrnHdrData({ ...mrnHdrData, Security_no: e.target.value });
                    }}
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '32px', color: 'darkblue' }}>Destination GST:</label>
                  <input maxLength={12} type="text" name="Des_gst" value={mrnHdrData.Des_gst}
                    onChange={(e) => {
                      // Update the Invoice_no value based on input
                      setMrnHdrData({ ...mrnHdrData, Des_gst: e.target.value });
                    }}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>


                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '24px', color: 'darkblue' }}>Destination State:</label>
                  <input type="text" name="Des_state_cd"
                    value={mrnHdrData.Des_state_cd}
                    onChange={(e) => {
                      setMrnHdrData({ ...mrnHdrData, Des_state_cd: e.target.value });
                    }}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>




              </div>

              <div>
          
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '40px', color: 'darkblue' }}>MRN Dt:</label>
                  <input
                    type="date"
                    value={
                      mrnHdrData.MRN_Dt
                        ? formatDateToDateInput(mrnHdrData.MRN_Dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    onChange={(e) => {
                      const updatedMRNData = { ...mrnHdrData, MRN_Dt: e.target.value };
                      setMrnHdrData(updatedMRNData); // Set updated MRN_Dt
                    }}
                    style={{
                      border: 'none',
                      borderLeft: '2px solid #D1D5DB',
                      padding: '10px',
                      width: '50%',
                      outline: 'none',
                      backgroundColor: 'transparent',
                    }}
                  />
                </div>


                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ marginRight: "10px", color: "darkblue" }}>Invoice Date:</label>
                  <input
                  type="date"
                    // value={mrnHdrData.Invoice_dt ? new Date(mrnHdrData.Invoice_dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    value={
                      mrnHdrData.Invoice_dt
                        ? formatDateToDateInput(mrnHdrData.Invoice_dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    onChange={(e) => {
                      const updatedMRNData = { ...mrnHdrData, Invoice_dt: e.target.value };
                      setMrnHdrData(updatedMRNData); // Set updated MRN_Dt
                    }}
                    style={{
                      border: "none",
                      borderLeft: "2px solid #D1D5DB",
                      padding: "10px",
                      width: "50%",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '56px', color: 'darkblue' }}>DC Dt:</label>
                  <input

                   type="date"
                    // value={mrnHdrData.DC_Dt ? new Date(mrnHdrData.DC_Dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    value={
                      mrnHdrData.DC_Dt
                        ? formatDateToDateInput(mrnHdrData.DC_Dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    onChange={(e) => {
                      const updatedMRNData = { ...mrnHdrData, DC_Dt: e.target.value };
                      setMrnHdrData(updatedMRNData); // Set updated MRN_Dt
                    }}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '21px', color: 'darkblue' }}>Security Dt:</label>
                  <input
                    // value={mrnHdrData.Security_dt ? new Date(mrnHdrData.Security_dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    type="date"
                    value={
                      mrnHdrData.Security_dt
                        ? formatDateToDateInput(mrnHdrData.Security_dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    onChange={(e) => {
                      const updatedMRNData = { ...mrnHdrData, Security_dt: e.target.value };
                      setMrnHdrData(updatedMRNData); // Set updated MRN_Dt
                    }}

                  

                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
              </div>

              <div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
        <select style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} >
          <option value="">Select</option>   Des_gst
        </select>
      </div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                  <input
                    // value={mrnHdrData.Supplier_cd}
                    value={
                      supplierList.find(supplier => supplier.Supp_cd === mrnHdrData.Supplier_cd)?.Supp_Nm || ''
                    }
                    style={{
                      border: 'none',
                      borderLeft: '2px solid #D1D5DB',
                      padding: '10px',
                      width: '50%',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      display: 'inline-block',
                    }}
                  >

                  </input>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '22px', color: 'darkblue' }}>Credit Days:</label>
                  <input

                    maxLength={4}
                    value={mrnHdrData.Credit_days}
                    onChange={(e) => {
                      setMrnHdrData({ ...mrnHdrData, Credit_days: e.target.value });
                    }}

                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '54px', color: 'darkblue' }}>Due Dt:</label>
                  <input

                    // value={mrnHdrData.Due_dt}
                    // value={mrnHdrData.Due_dt ? new Date(mrnHdrData.Due_dt).toISOString().split('T')[0].split('-').reverse().join('-') : ''}
                    type="date"
                    value={
                      mrnHdrData.Due_dt
                        ? formatDateToDateInput(mrnHdrData.Due_dt) // Format the date for the input field
                        : '' // If there's no date, show an empty input
                    }
                    onChange={(e) => {
                      const updatedMRNData = { ...mrnHdrData, Due_dt: e.target.value };
                      setMrnHdrData(updatedMRNData); // Set updated MRN_Dt
                    }}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '13px', color: 'darkblue' }}>Supplier GST:</label>
        <input style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
      </div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '14px', color: 'darkblue' }}>Supplier GST:</label>
                  <input
                    value={mrnHdrData.Supp_gst}
                    maxLength={12}
                    style={{
                      border: 'none',
                      borderLeft: '2px solid #D1D5DB',
                      padding: '10px',
                      width: '50%',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      display: 'inline-block',
                    }}
                  >

                  </input>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                  <input type="text" value={mrnHdrData.Supp_state_cd}
                    style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
        <input type="text" name="Supp_state_cd" value={supplier ? extractStateFromGST(supplier.Gst_No) : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
      </div> */}
              </div>
            </div>

            <div style={{ overflowY: "auto", maxHeight: "240px" }}>
              <table style={{ minWidth: "100%", borderCollapse: "collapse", border: "1px solid black" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>SI.No</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>PO No</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>Item Code</th>
                    {/* <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>Item Description</th> */}
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>UOM</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>HSN CODE</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>GST %</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue" }}>PO Qty</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue", width: '230px' }}>Received Qty</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue", width: '230px' }}>Accepted Qty</th>
                    <th style={{ border: "1px solid black", textAlign: "center", color: "darkblue", width: '230px' }}>Rejected Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {mrnDtlData.map((item, index) => ( */}
                  {/* {(mrnDtlData.length > 0 ? mrnDtlData : Array(4).fill({})).map((item, index) => ( */}
                  {rowsToDisplay.map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{index + 1}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.PO_No}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Item_cd}</td>
                      {/* <td style={{ border: "1px solid black", padding: "8px" }}>{item.itemDesc}</td> */}
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.UOM}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Hsn_code}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.GST_Rate}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.PO_qty}</td>
                      {/* <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                        value={item.Received_qty} 
                        type='text'
                    //     onChange={(e) => {
                    //   setMrnHdrData({ ...mrnHdrData, Received_qty: e.target.value });
                    // }}
                      style={{
                      width: '100%',
                      // padding: '5px',
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent',
                    }}
                      />  
                        
                        </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{item.Accepted_qty}</td>
                      <td style={{ border: "1px solid black", padding: "8px", }}>{item.Rejected_qty}</td> */}


                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          type='number'
                          value={item.Received_qty}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Check if a decimal is entered
                            if (inputValue.includes('.')) {

                              let parts = inputValue.split('.');
                              if (parts[1] && parts[1].length > 2) {
                                // Limit the digits to 2 after the decimal
                                e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                              }
                            }
                            const updatedData = [...mrnDtlData];
                            updatedData[index].Received_qty = e.target.value;
                            setMrnDtlData(updatedData);
                          }}
                          style={{
                            width: '100%',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            textAlign: 'left'
                          }}
                        />
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          type='number'
                          value={item.Accepted_qty}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Check if a decimal is entered
                            if (inputValue.includes('.')) {

                              let parts = inputValue.split('.');
                              if (parts[1] && parts[1].length > 2) {
                                // Limit the digits to 2 after the decimal
                                e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                              }
                            }
                            const updatedData = [...mrnDtlData];
                            updatedData[index].Accepted_qty = e.target.value;
                            setMrnDtlData(updatedData);
                          }}
                          style={{
                            width: '100%',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            textAlign: 'left'
                          }}
                        />
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          type='number'
                          value={item.Rejected_qty}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Check if a decimal is entered
                            if (inputValue.includes('.')) {

                              let parts = inputValue.split('.');
                              if (parts[1] && parts[1].length > 2) {
                                // Limit the digits to 2 after the decimal
                                e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                              }
                            }
                            const updatedData = [...mrnDtlData];
                            updatedData[index].Rejected_qty = e.target.value;
                            setMrnDtlData(updatedData);
                          }}
                          style={{
                            width: '100%',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            textAlign: 'left'
                          }}
                        />
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


        )}

      </div>

    </>
  );
};

export default MatirialReceipt;