// import React, { useState, useEffect } from 'react';
// import { BsBoxArrowRight, BsPersonCircle } from 'react-icons/bs';
// import { useNavigate } from 'react-router-dom';
// import A1 from '../Images/logo-w.png';

// const Dashboard = () => {
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [userData, setUserData] = useState(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const storedUserData = JSON.parse(sessionStorage.getItem('user'));
//         if (storedUserData) {
//             setUserData(storedUserData);
//         } else {
//             navigate('/');
//         }
//     }, [navigate]);
//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//     };

//     const handleLogout = () => {
//         sessionStorage.removeItem('user');
//         sessionStorage.removeItem('userType');
//         navigate('/');
//         setDropdownVisible(false);
//     };

//     const handleAddItemClick = () => {
//         navigate('/itemmasterform-itemmasterform');
//     };

//     const handleAddItemClicks = () => {
//         navigate('/suppliermasterform-suppliermasterform');
//     };

//     const handlePurchaseIndentClick = () => {
//         navigate('/purchaseindentform-purchaseindentform');
//     };

//     const handlePurchaseOrderClick = () => {
//         navigate('/purchaseorder-purchaseorder');
//     };

//     return (
//         <div>
//             <div style={{ backgroundColor: "#dbc4a9" }}>
//                 <div className="container d-flex justify-content-between align-items-center">
//                     <img src={A1} alt="item" style={{ width: "20%" }} />
//                     <h2 className="mb-5 mt-5" style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>E-MEDHA ERP</h2>
//                     <div className="position-relative">
//                         <BsPersonCircle size={30} style={{ cursor: 'pointer' }} onClick={toggleDropdown} />
//                         {dropdownVisible && (
//                             <div style={{ position: 'absolute', right: '20px', top: '100%', backgroundColor: '#fff', zIndex: 1000, borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
//                                 <button
//                                     onClick={handleLogout}
//                                     style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', padding: '10px 15px', cursor: 'pointer', transition: 'background-color 0.3s', display: 'flex', alignItems: 'center' }}
//                                     onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
//                                     onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
//                                 >
//                                     <BsBoxArrowRight style={{ marginRight: '5px' }} />
//                                     Logout
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>

//             {userData?.item_mst === "1" && (
//                 <div className="d-flex justify-content-center mt-5">
//                     <button className="btn btn-lg"
//                         onClick={handleAddItemClick}
//                         style={{ background: 'linear-gradient(145deg, #1e90ff, #4682b4)', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(1.05)';
//                             e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)';
//                             e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
//                         }}
//                     >
//                         ITEM MASTER
//                     </button>
//                 </div>
//             )}

//             {userData?.supp_mst === "1" && (
//                 <div className="d-flex justify-content-center mt-4">
//                     <button className="btn btn-lg"
//                         onClick={handleAddItemClicks}
//                         style={{ background: 'darkblue', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(1.05)';
//                             e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)';
//                             e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
//                         }}
//                     >
//                         SUPPLIER MASTER
//                     </button>
//                 </div>
//             )}

//             {/* New "PURCHASE INDENT" button */}
//             {userData?.purchase_int === "1" && (
//                 <div className="d-flex justify-content-center mt-4">
//                     <button className="btn btn-lg"
//                         onClick={handlePurchaseIndentClick}
//                         style={{ background: 'darkgreen', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(1.05)';
//                             e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)';
//                             e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
//                         }}
//                     >
//                         PURCHASE INDENT
//                     </button>
//                 </div>
//             )}

//             {/* New "PURCHASE ORDER" button */}
//             {userData?.purchase_int === "1" && (
//                 <div className="d-flex justify-content-center mt-4">
//                     <button className="btn btn-lg"
//                         onClick={handlePurchaseOrderClick}
//                         style={{ background: 'linear-gradient(145deg,rgb(31, 53, 76),rgb(62, 115, 157))', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(1.05)';
//                             e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)';
//                             e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
//                         }}
//                     >
//                         PURCHASE ORDER
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Dashboard;

import React, { useState, useEffect } from 'react';
import { BsBoxArrowRight, BsPersonCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';

const Dashboard = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserData = JSON.parse(sessionStorage.getItem('user'));
        if (storedUserData) {
            setUserData(storedUserData);
        } else {
            navigate('/');
        }
    }, [navigate]);
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userType');
        navigate('/');
        setDropdownVisible(false);
    };

    const handleAddItemClick = () => {
        navigate('/itemmasterform-itemmasterform');
    };

    const handleAddItemClicks = () => {
        navigate('/suppliermasterform-suppliermasterform');
    };

    const handlePurchaseIndentClick = () => {
        navigate('/purchaseindentform-purchaseindentform');
    };

    const handlePurchaseOrderClick = () => {
        navigate('/purchaseorder-purchaseorder');
    };

    const handleMaterialReceiptClick = () => {
        navigate('/matirialreceipt-matirialreceipt');
    };

   const handleMaterialIssuesClick = () => {
        navigate('/material-issues');
    };
    
    return (
        <div>
            <div style={{ backgroundColor: "#dbc4a9" }}>
                <div className="container d-flex justify-content-between align-items-center">
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h2 className="mb-5 mt-5" style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>E-MEDHA ERP</h2>
                    <div className="position-relative">
                        <BsPersonCircle size={30} style={{ cursor: 'pointer' }} onClick={toggleDropdown} />
                        {dropdownVisible && (
                            <div style={{ position: 'absolute', right: '20px', top: '100%', backgroundColor: '#fff', zIndex: 1000, borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                                <button
                                    onClick={handleLogout}
                                    style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', padding: '10px 15px', cursor: 'pointer', transition: 'background-color 0.3s', display: 'flex', alignItems: 'center' }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                                >
                                    <BsBoxArrowRight style={{ marginRight: '5px' }} />
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {userData?.item_mst === "1" && (
                <div className="d-flex justify-content-center mt-5">
                    <button className="btn btn-lg"
                        onClick={handleAddItemClick}
                        style={{ background: 'linear-gradient(145deg, #1e90ff, #4682b4)', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
                        }}
                    >
                        ITEM MASTER
                    </button>
                </div>
            )}

            {userData?.supp_mst === "1" && (
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-lg"
                        onClick={handleAddItemClicks}
                        style={{ background: 'darkblue', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
                        }}
                    >
                        SUPPLIER MASTER
                    </button>
                </div>
            )}

            {/* New "PURCHASE INDENT" button */}
            {userData?.purchase_int === "1" && (
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-lg"
                        onClick={handlePurchaseIndentClick}
                        style={{ background: 'darkgreen', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
                        }}
                    >
                        PURCHASE INDENT
                    </button>
                </div>
            )}

            {/* New "PURCHASE ORDER" button */}
            {userData?.purchase_ord === "1" && (
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-lg"
                        onClick={handlePurchaseOrderClick}
                        style={{ background: 'linear-gradient(145deg,rgb(31, 53, 76),rgb(62, 115, 157))', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
                        }}
                    >
                        PURCHASE ORDER
                    </button>
                </div>
            )}

            {userData?.material_recaipt === 1 && (
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-lg"
                        onClick={handleMaterialReceiptClick}
                        style={{ background: 'linear-gradient(145deg,rgb(12, 12, 12),rgb(17, 121, 206))', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
                        }}
                    >
                        MATERIAL RECEIPT
                    </button>
                </div>
            )}
                        {userData?.material_request === 1 && (
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-lg"
                        onClick={handleMaterialIssuesClick}
                        style={{ background: 'linear-gradient(145deg,rgb(12, 12, 12),rgb(17, 121, 206))', border: 'none', color: '#fff', padding: '12px 24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', fontWeight: 'bold', transition: 'transform 0.3s, box-shadow 0.3s', cursor: 'pointer' }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)';
                        }}
                    >
                        Material Issues
                    </button>
                </div>
            )}

        </div>
    );
};

export default Dashboard;