import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaSave, } from 'react-icons/fa';
import { FaCheck, FaPlus } from 'react-icons/fa6';
import { HiOutlineRefresh } from 'react-icons/hi';
import { IoPrint } from 'react-icons/io5';
import { LuView } from 'react-icons/lu';
import { MdEdit } from 'react-icons/md';
import { RiCloseLargeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';

const Indent = () => {
    const [items, setItems] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ Location_id: '', Indent_no: '', Indent_Header_Dt: '', Indent_Req_by: '', Dept_id: '', itemCd: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' });
    const [showForm, setShowForm] = useState(false);
    const [employe, setEmploye] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [employee, setEmployee] = useState(false);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userName = user ? user.User_nm : 'Guest';


    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem('user'));
        if (userData) {
            setEmploye(userData);
        } else {
            console.log('No user data found in sessionStorage');
        }
    }, []);
    useEffect(() => {
        axios.get('https://erpapi.emedha.in/pages/api/api/location')
            .then((response) => {
                setLocations(response.data);
            })
            .catch((error) => {
                console.error('Error fetching main groups:', error);
            });

        axios.get('https://erpapi.emedha.in/pages/api/api/depertament')
            .then((response) => {
                setDepartments(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/suppliers')
            .then((response) => {
                setSuppliers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/itemsmasterform')
            .then((response) => {
                setItems(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/employeee')
            .then((response) => {
                setEmployee(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
    }, []);
    console.log('Employee data:', employee);
    const isApproved = employe && employe.approve_permission === 1;
    const [rows, setRows] = useState([...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' })));

    const handleItemSelects = (itemCd, index) => {
        const selectedItem = items.find(item => item.itemCd === Number(itemCd));
        if (selectedItem) {
            const updatedRows = [...rows];
            updatedRows[index] = { ...updatedRows[index], itemCd: selectedItem.itemCd, itemDesc: selectedItem.itemDesc, itemUOM: selectedItem.itemUOM };
            setRows(updatedRows);
        }
    };
    // const handleItemSearch = (value, index) => {
    //     const selectedItem = items.find(item => item.itemCd === Number(value));
    //     if (selectedItem) {
    //         const updatedRows = [...rows];
    //         updatedRows[index] = { ...updatedRows[index], itemCd: selectedItem.itemCd, itemDesc: selectedItem.itemDesc, itemUOM: selectedItem.itemUOM, };
    //         setRows(updatedRows);
    //     } else {
    //         const updatedRows = [...rows];
    //         updatedRows[index] = { ...updatedRows[index], itemCd: value, itemDesc: '', itemUOM: '', };
    //         setRows(updatedRows);
    //     }
    // };

    const handleSupplierSelect = (Supp_id, index) => {
        const updatedRows = [...rows];
        updatedRows[index] = {
            ...updatedRows[index],
            Supp_id: Supp_id,
        };
        setRows(updatedRows);
    };

    const [indentNos, SetIndentNos] = useState('');
    const [Location_id, setLocation_id] = useState(null);

    const handleLocationSelects = (Location_id) => {
        setSearchTerm(Location_id);
        setLocation_id(Location_id);
        fetchIndentDetails(Location_id);
        fetchIndentNos();
    };

    const handleIndentSelect = (selectedIndentNo) => {
        SetIndentNos(selectedIndentNo);
        if (Location_id) {
            fetchIndentDetails(Location_id, selectedIndentNo);
        } else {
            console.error("Location_id is undefined.");
        }
    };
    console.log(indentNos)

    const fetchIndentDetails = async (Location_id, indentNos) => {
        try {
            const response = await axios.get(`https://erpapi.emedha.in/pages/api/combined-indent/${Location_id}/${indentNos}`);
            const data = response.data.data;
            console.log(response.data)
            if (data && data.length > 0) {
                const updatedRows = [...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' }));
                data.forEach((row, index) => {
                    updatedRows[index] = {
                        itemCd: row.itemCd || '',
                        itemDesc: row.itemDesc || '',
                        itemUOM: row.itemUOM || '',
                        Req_dt: row.Req_dt ? row.Req_dt : new Date().toISOString().split('T')[0],
                        Supp_id: row.Supp_id || '',
                        Qty: row.Qty || '',
                        Remarks: row.Remarks || '',
                        Approved_qty: row.Approved_qty || '',
                        Approved_by: row.Approved_by || '',
                        Emp_Name: row.Emp_Name || '',
                        close: row.Close || ''
                    };
                });
                setRows(updatedRows);
                const uniqueData = data.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.Location_id === value.Location_id &&
                        t.Indent_no === value.Indent_no &&
                        t.itemCd === value.itemCd
                    ))
                );
                if (uniqueData.length > 0) {
                    const indentHeader = uniqueData[0];
                    setFormData({
                        Location_id: indentHeader.Location_id || '',
                        Location_Desc: indentHeader.Location_Desc || '',
                        Indent_no: indentHeader.Indent_no || '',
                        Indent_Header_Dt: indentHeader.Indent_Header_Dt ? indentHeader.Indent_Header_Dt : new Date().toISOString().split('T')[0],  // Make sure Indent_Header_Dt is set
                        Indent_Req_by: indentHeader.Indent_Req_by || '',
                        Dept_id: indentHeader.Dept_id || ''
                    });
                }
            }
        } catch (err) {
            console.error('Error fetching indent details:', err);
        }
    };
    console.log(user)

    const [filteredItems, setFilteredItems] = useState([]);

    const fetchIndentNos = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/sss/pur-indent');
            const data = await response.json();
            if (data && typeof data === 'object') {
                setFilteredItems(data);
            }
        } catch (error) {
            console.error('Error fetching indent numbers:', error);
        }
    };

    useEffect(() => {
        fetchIndentNos();
    }, []);

    const [dropdownVisible1, setDropdownVisible1] = useState(false)

    const [searchTerm, setSearchTerm] = useState("");
    const [isEditing, setIsEditing] = useState(false);

    // const handleSave = async () => {
    //     const { Location_id, Indent_no, Indent_Header_Dt, Dept_id } = formData;
    //     if (!Location_id || !Indent_no) {
    //         alert("Please fill all required fields");
    //         return;
    //     }

    //     let formattedDate = '';
    //     if (Indent_Header_Dt && !isNaN(new Date(Indent_Header_Dt).getTime())) {
    //         formattedDate = new Date(Indent_Header_Dt).toISOString().split('T')[0];
    //     } else {
    //         formattedDate = new Date().toISOString().split('T')[0];
    //     }

    //     const form = new FormData();
    //     form.append('Location_id', Location_id);
    //     form.append('Indent_no', Indent_no);
    //     form.append('Indent_Header_Dt', formattedDate);
    //     form.append('Indent_Req_by', user ? user.id : '');
    //     form.append('Dept_id', Dept_id);

    //     rows.forEach((row, index) => {
    //         let reqDate = row.Req_dt || new Date().toISOString().split('T')[0];
    //         form.append(`items[${index}][itemCd]`, row.itemCd);
    //         form.append(`items[${index}][itemDesc]`, row.itemDesc);
    //         form.append(`items[${index}][itemUOM]`, row.itemUOM);
    //         form.append(`items[${index}][Req_dt]`, reqDate);
    //         form.append(`items[${index}][Supp_id]`, row.Supp_id);
    //         form.append(`items[${index}][Qty]`, row.Qty);
    //         form.append(`items[${index}][Remarks]`, row.Remarks);
    //         if (row.Approved_qty) {
    //             form.append(`items[${index}][Approved_qty]`, row.Approved_qty);
    //         }
    //         if (row.Approved_by || formMode === 'edit') {
    //             form.append(`items[${index}][Approved_by]`, row.Approved_by || user.id);
    //         }

    //         if (row.close) {
    //             form.append(`items[${index}][close]`, row.close);
    //         }
    //     });

    //     const requestData = {
    //         Location_id,
    //         Indent_no: Number(Indent_no),
    //         Indent_Header_Dt: formattedDate,
    //         Indent_Req_by: user ? user.id : '',
    //         Dept_id,
    //         items: rows.map((row) => ({
    //             itemCd: row.itemCd,
    //             itemDesc: row.itemDesc,
    //             itemUOM: row.itemUOM,
    //             Req_dt: row.Req_dt || new Date().toISOString().split('T')[0],
    //             Supp_id: row.Supp_id,
    //             Qty: row.Qty,
    //             Remarks: row.Remarks,
    //             ...(row.Approved_qty && { Approved_qty: row.Approved_qty }),
    //             ...(row.Approved_by ? { Approved_by: row.Approved_by } : { Approved_by: user.id }),
    //             ...(row.close && { close: row.close }),
    //         }))
    //     };

    //     try {
    //         if (isEditing) {
    //             await axios.put(`https://erpapi.emedha.in/pages/api/pur-indent/${Indent_no}`, requestData);
    //             alert('Item updated successfully!');
    //         } else {
    //             const response = await axios.post('http://localhost:3007/pages/api/api/pur-indent', requestData);
    //             console.log('Response from backend:', response);
    //             alert('Items added successfully! Indent No: ' + Indent_no);
    //         }
    //     } catch (error) {
    //         alert('Error saving items');
    //         console.error('Error saving items:', error);
    //     }
    // };


    // const handleSave = async () => {
    //     const { Location_id, Indent_no, Indent_dt, Dept_id } = formData;
    //     if (!Location_id || !Indent_no) {
    //         alert("Please fill all required fields");
    //         return;
    //     }

    //     let formattedDate = '';
    //     if (Indent_dt && !isNaN(new Date(Indent_dt).getTime())) {
    //         formattedDate = new Date(Indent_dt).toISOString().split('T')[0];
    //     } else {
    //         formattedDate = new Date().toISOString().split('T')[0];
    //     }

    //     const form = new FormData();
    //     form.append('Location_id', Location_id);
    //     form.append('Indent_no', Indent_no);
    //     form.append('Indent_dt', formattedDate);
    //     form.append('Indent_Req_by', user ? user.id : '');
    //     form.append('Dept_id', Dept_id);

    //     rows.forEach((row, index) => {
    //         let reqDate = row.Req_dt || new Date().toISOString().split('T')[0];
    //         form.append(`items[${index}][itemCd]`, row.itemCd);
    //         form.append(`items[${index}][itemDesc]`, row.itemDesc);
    //         form.append(`items[${index}][itemUOM]`, row.itemUOM);
    //         form.append(`items[${index}][Req_dt]`, reqDate);
    //         form.append(`items[${index}][Supp_id]`, row.Supp_id);
    //         form.append(`items[${index}][Qty]`, row.Qty);
    //         form.append(`items[${index}][Remarks]`, row.Remarks);
    //         if (row.Approved_qty) {
    //             form.append(`items[${index}][Approved_qty]`, row.Approved_qty);
    //         }
    //         if (formMode !== 'create') {
    //             if (row.Emp_Name) {
    //                 form.append(`items[${index}][Emp_Name]`, row.Emp_Name); // Only include if not in 'create' mode
    //             }
    //             if (row.Approved_by || formMode === 'edit'||formMode === 'approve') {
    //                 form.append(`items[${index}][Approved_by]`, row.Approved_by || user.id);
    //             }
    //         }

    //         if (row.close) {
    //             form.append(`items[${index}][close]`, row.close);
    //         }
    //     });

    //     const requestData = {
    //         Location_id,
    //         Indent_no: Number(Indent_no),
    //         Indent_dt: formattedDate,
    //         Indent_Req_by: user ? user.id : '',
    //         Dept_id,
    //         items: rows.map((row) => ({
    //             itemCd: row.itemCd,
    //             itemDesc: row.itemDesc,
    //             itemUOM: row.itemUOM,
    //             Req_dt: row.Req_dt || new Date().toISOString().split('T')[0],
    //             Supp_id: row.Supp_id,
    //             Qty: row.Qty,
    //             Remarks: row.Remarks,
    //             ...(row.Approved_qty && { Approved_qty: row.Approved_qty }),
    //             // ...(row.Approved_by ? { Approved_by: row.Approved_by } : { Approved_by: user.id }),
    //             ...(row.Approved_by && formMode !== 'create' ? { Approved_by: row.Approved_by } : {}),
    //             ...(row.close && { close: row.close }),
    //         }))
    //     };

    //     try {
    //         if (isEditing) {
    //             await axios.put(`https://erpapi.emedha.in/pages/api/pur-indent/${Indent_no}`, requestData);
    //             alert('Item updated successfully!');
    //         } else {
    //             const response = await axios.post('http://localhost:3007/pages/api/api/pur-indent', requestData);
    //             console.log('Response from backend:', response);
    //             alert('Items added successfully! Indent No: ' + Indent_no);
    //         }
    //     } catch (error) {
    //         alert('Error saving items');
    //         console.error('Error saving items:', error);
    //     }
    // };


    const handleSave = async () => {
        const { Location_id, Indent_no, Indent_dt, Dept_id } = formData;

        // Check if required fields are present
        if (!Location_id || !Indent_no || !Dept_id || (formMode === 'create' && !user?.id)) {
            alert("Please fill all required fields, including Indent_Req_by in create mode");
            return;
        }

        let formattedDate = '';
        if (Indent_dt && !isNaN(new Date(Indent_dt).getTime())) {
            formattedDate = new Date(Indent_dt).toISOString().split('T')[0];
        } else {
            formattedDate = new Date().toISOString().split('T')[0];
        }

        // Create a FormData object for file upload if needed
        const form = new FormData();
        form.append('Location_id', Location_id);
        form.append('Indent_no', Indent_no);
        form.append('Indent_dt', formattedDate);

        // In create mode, set Indent_Req_by from user
        if (formMode === 'create') {
            form.append('Indent_Req_by', user ? user.id : '');
        } else if (formMode === 'approve') {
            // In approve mode, do not change Indent_Req_by, keep it as is
            form.append('Indent_Req_by', formData.Indent_Req_by); // retain previous value
        }

        form.append('Dept_id', Dept_id);

        // Loop through items and append their data
        rows.forEach((row, index) => {
            let reqDate = row.Req_dt || new Date().toISOString().split('T')[0];
            form.append(`items[${index}][itemCd]`, row.itemCd);
            form.append(`items[${index}][itemDesc]`, row.itemDesc);
            form.append(`items[${index}][itemUOM]`, row.itemUOM);
            form.append(`items[${index}][Req_dt]`, reqDate);
            form.append(`items[${index}][Supp_id]`, row.Supp_id);
            form.append(`items[${index}][Qty]`, row.Qty);
            form.append(`items[${index}][Remarks]`, row.Remarks);

            if (row.Approved_qty) {
                form.append(`items[${index}][Approved_qty]`, row.Approved_qty);
            }

            // Only send Approved_by during approve mode, otherwise, keep it null in create mode or edit mode
            if (formMode === 'approve') {
                form.append(`items[${index}][Approved_by]`, row.Approved_by || user.id);
            } else {
                form.append(`items[${index}][Approved_by]`, null); // In create and edit mode, it will be null
            }

            // Only send 'close' in approve mode
            if (formMode === 'approve') {
                form.append(`items[${index}][close]`, row.close);
            }
        });

        // Prepare the request data for the backend
        const requestData = {
            Location_id,
            Indent_no: Number(Indent_no),
            Indent_dt: formattedDate,
            Dept_id, // Include Dept_id
            // Only include Indent_Req_by in create mode, keep it as is in approve mode
            ...(formMode === 'create' ? { Indent_Req_by: user ? user.id : '' } : { Indent_Req_by: formData.Indent_Req_by }),
            items: rows.map((row) => ({
                itemCd: row.itemCd,
                itemDesc: row.itemDesc,
                itemUOM: row.itemUOM,
                Req_dt: row.Req_dt || new Date().toISOString().split('T')[0],
                Supp_id: row.Supp_id,
                Qty: row.Qty,
                Remarks: row.Remarks,
                ...(row.Approved_qty && { Approved_qty: row.Approved_qty }),
                ...(formMode === 'approve' ? { Approved_by: row.Approved_by || user.id } : { Approved_by: null }), // Ensure null in edit mode
                ...(formMode === 'approve' ? { close: row.close } : {}),
            }))
        };

        try {
            if (isEditing) {
                await axios.put(`https://erpapi.emedha.in/pages/api/pur-indent/${Indent_no}`, requestData);
                alert('Item updated successfully!');
            } else {
                const response = await axios.post('https://erpapi.emedha.in/pages/api/api/pur-indent', requestData);
                console.log('Response from backend:', response);
                alert('Items added successfully! Indent No: ' + Indent_no);
            }
        } catch (error) {
            alert('Error saving items');
            console.error('Error saving items:', error);
        }
    };




    const [showSearch, setShowSearch] = useState(false);
    const [showPlusSearch, setShowPlusSearch] = useState(false);

    const handleRefresh = () => {
        setFormData({ Location_id: '', Indent_no: '', Indent_Header_Dt: '', Indent_Req_by: '', Dept_id: '', itemCd: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' });
        setRows([...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' })));
    };

    const handleInputChange = (e, index = null) => {
        const { name, value } = e.target;

        console.log('Input changed:', name, value);

        if (name === 'Indent_Req_by') {
            setFormData((prevState) => ({
                ...prevState,
                Indent_Req_by: user ? user.id : '',
            }));
        } else if (index !== null) {
            const updatedRows = [...rows];
            updatedRows[index] = {
                ...updatedRows[index],
                [name]: value,
            };
            setRows(updatedRows);
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));

            if (name === 'Location_id' && value) {
                const selectedLocation = locations.find(location => location.Location_Desc === value);
                if (selectedLocation) {
                    setFormData((prevState) => ({
                        ...prevState,
                        Indent_no: selectedLocation.Location_Id,
                    }));
                }
            }
            if (name === 'Indent_Req_by' && formMode !== 'edit') {
                setFormData(prevState => ({
                    ...prevState,
                    Indent_Req_by: user ? user.id : '',
                }));
            }

            if (name === 'Approved_by' && formMode !== 'edit') {
                setFormData(prevState => ({
                    ...prevState,
                    Approved_by: user ? user.id : '',
                }));
            }
        }
    };

    const handleQtyChange = (e, index) => {
        let value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            const updatedRows = [...rows];
            updatedRows[index] = {
                ...updatedRows[index],
                Qty: value,
            };
            setRows(updatedRows);
        }
    };

    const handleApproveQtyChange = (e, index) => {
        let value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            const updatedRows = [...rows];
            updatedRows[index] = {
                ...updatedRows[index],
                Approved_qty: value,
            };
            setRows(updatedRows);
        }
    };


    const handleLocationSelect = async (locationId) => {
        setFormData((prevState) => ({ ...prevState, Location_id: locationId, }));
        try {
            const response = await axios.get(`https://erpapi.emedha.in/pages/api/api/generate-indent-no?Location_id=${locationId}`);
            if (response.status === 200) {
                const newIndentNo = response.data.indent_no;
                setFormData((prevState) => ({ ...prevState, Indent_no: newIndentNo, }));
            } else {
                console.error('Failed to generate indent number');
            }
        } catch (err) {
            console.error('Error generating indent number:', err.message);
        }
    };

    const handleBack = () => { navigate(-1); };

    const [formMode, setFormMode] = useState('');
    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};

    const getButtonState = (permissionType) => {
        switch (formMode) {
            case 'create':
                return permissionType === 'create' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'edit':
                return permissionType === 'edit' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'view':
                return permissionType === 'view' || permissionType === 'clear' || permissionType === 'close';
            case 'approve':
                return permissionType === 'clear' || permissionType === 'save' || permissionType === 'approve' || permissionType === 'print' || permissionType === 'close';
            default:
                return false;
        }
    };


    const handlePrint = () => {

    };


    return (
        <>
            <div style={{ margin: '0 auto', backgroundColor: '#dbc4a9', padding: '1rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid black', borderRadius: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>
                        PURCHASE INDENT
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.add && userPermissions.add !== 0 && getButtonState('create') ? 1 : 0.5
                        }}>
                            <FaPlus size={30} onClick={() => {
                                if (userPermissions.add && userPermissions.add !== 0) {
                                    setFormData({ Location_id: '', Indent_no: '', Indent_Header_Dt: '', Indent_Req_by: '', Dept_id: '', itemCd: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' });
                                    setRows([...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' })));
                                    setShowForm(true);
                                    setShowSearch(false);
                                    setShowPlusSearch(true);
                                    setFormMode('create');
                                    setIsEditing(false);
                                } else {
                                    alert("You do not have permission to Add.");
                                }
                            }} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.edit && userPermissions.edit !== 0 && getButtonState('edit') ? 1 : 0.5
                        }}>
                            <MdEdit size={30} onClick={() => {
                                if (userPermissions.edit && userPermissions.edit !== 0) {
                                    setFormData({ Location_id: '', Indent_no: '', Indent_Header_Dt: '', Indent_Req_by: '', Dept_id: '', itemCd: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' });
                                    setRows([...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' })));
                                    setShowForm(true);
                                    setShowSearch(true);
                                    setShowPlusSearch(false);
                                    setFormMode('edit');
                                    setIsEditing(true);
                                } else {
                                    alert("You do not have permission to edit.");
                                }
                            }} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.view && userPermissions.view !== 0 && getButtonState('view') ? 1 : 0.5
                        }}>
                            <LuView size={30} onClick={() => {
                                if (userPermissions.view && userPermissions.view !== 0) {
                                    setFormData({ Location_id: '', Indent_no: '', Indent_Header_Dt: '', Indent_Req_by: '', Dept_id: '', itemCd: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' });
                                    setRows([...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' })));
                                    setShowForm(true);
                                    setShowSearch(true);
                                    setShowPlusSearch(false);
                                    setFormMode('view');
                                    setIsEditing(false);
                                } else {
                                    alert("You do not have permission to view.");
                                }
                            }} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? "pointer" : "not-allowed",
                            opacity: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? 1 : 0.5
                        }}>
                            <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? "pointer" : "not-allowed",
                            opacity: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? 1 : 0.5
                        }}>
                            <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSave : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? "pointer" : "not-allowed",
                            opacity: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? 1 : 0.5
                        }}>
                            <FaCheck size={30} onClick={() => {
                                if (userPermissions.approve && userPermissions.approve !== 0) {
                                    setFormData({ Location_id: '', Indent_no: '', Indent_Header_Dt: '', Indent_Req_by: '', Dept_id: '', itemCd: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' });
                                    setRows([...Array(20)].map(() => ({ itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: '' })));
                                    setShowForm(true);
                                    setShowSearch(true);
                                    setShowPlusSearch(false);
                                    setFormMode('approve');
                                    setIsEditing(true);
                                } else {
                                    alert("You do not have permission to approve.");
                                }
                            }} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? "pointer" : "not-allowed",
                            opacity: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? 1 : 0.5
                        }} title='Print'>
                            <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? "pointer" : "not-allowed",
                            opacity: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? 1 : 0.5
                        }} title='Exit'>
                            <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                        </div>
                    </div>
                </div>
                <p>Welcome To Purchase Indent: {userName}</p>
                <hr />
                {showForm && (
                    <>
                        <div>
                            <div className='mt-5' style={{ marginBottom: '16px' }}>
                                {showPlusSearch && (
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '52px', color: 'darkblue' }}>Location:</label>
                                            <select name="Location_id" value={formData.Location_id || ''} onChange={(e) => handleLocationSelect(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                                <option value="">Select</option>
                                                {locations.map(location => (<option key={location.Location_Id} value={location.Location_Id}>{location.Location_Desc}</option>))}
                                            </select>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '41px', color: 'darkblue' }}>Indent No: </label>
                                            <input type="number" name="Indent_no" value={formData.Indent_no || ''} onChange={handleInputChange} style={{ display: 'none', border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} readOnly />
                                        </div>
                                    </>
                                )}
                                {showSearch && (
                                    <>
                                        <div>
                                            <label style={{ marginRight: '22px', color: 'darkblue' }}>Location Edit:</label>
                                            <input type="text" placeholder="Search by Location Id" value={searchTerm}
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value);
                                                    setDropdownVisible1(true);
                                                }} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} />
                                            {dropdownVisible1 && !searchTerm && Object.keys(filteredItems).length > 0 && (
                                                <ul style={{ overflowY: "auto", border: "1px solid #e2e8f0", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '30%', width: '18%', marginLeft: '9%' }}>
                                                    {Object.keys(filteredItems).map((Location_Id) => (
                                                        <li key={Location_Id} onClick={() => { handleLocationSelects(Location_Id); setSearchTerm(Location_Id); setDropdownVisible1(false); }}
                                                            style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>{Location_Id}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '41px', color: 'darkblue' }}>Indent No:</label>
                                            <select
                                                onChange={(e) => { const selectedIndentNo = e.target.value; handleIndentSelect(selectedIndentNo); }} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                                <option value="">Select Indent No</option>
                                                {filteredItems[searchTerm]?.map((Indent_no) => (
                                                    <option key={Indent_no} value={Indent_no}>{Indent_no}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                )}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '14px', color: 'darkblue' }}>Indent Req By:</label>
                                    <input name="Indent_Req_by" value={formMode === 'view'
                                        ? (employee.find(emp => emp.Emp_id === formData.Indent_Req_by)?.Emp_Name || '')
                                        : formMode === 'edit' || formMode === 'approve'
                                            ? (employee.find(emp => emp.Emp_id === formData.Indent_Req_by)?.Emp_Name || '')
                                            : (user ? user.User_nm : '')
                                    }
                                        onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '32px', color: 'darkblue' }}>Department</label>
                                    <select name="Dept_id" value={formData.Dept_id || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'}>
                                        <option value="">Select</option>
                                        {departments.map(Dept_id => (<option key={Dept_id.Dept_id} value={Dept_id.Dept_id}>{Dept_id.Dept_name}</option>))}
                                    </select>
                                </div>
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '30px', color: 'darkblue' }}>Indent Date:</label>
                                    <input type="date" name="Indent_Header_Dt" value={formData.Indent_Header_Dt || new Date().toISOString().split('T')[0]} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                                </div> */}
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '30px', color: 'darkblue' }}>Indent Date:</label>
                                    <input
                                        type="date"
                                        name={formMode === 'create' ? 'Indent_dt' : 'Indent_Header_Dt'}
                                        value={formMode === 'create' ? formData.Indent_dt : (formData.Indent_Header_Dt ? new Date(formData.Indent_Header_Dt).toISOString().split('T')[0] : new Date().toISOString().split('T')[0])}
                                        onChange={handleInputChange}
                                        style={{
                                            border: 'none',
                                            borderLeft: '2px solid #D1D5DB',
                                            padding: '4px',
                                            margin: '4px 0',
                                            width: '20%',
                                            outline: 'none',
                                            backgroundColor: 'transparent'
                                        }}
                                        disabled={formMode === 'view' || formMode === 'approve'} // Disable in view and approve modes
                                    />
                                </div> */}

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '30px', color: 'darkblue' }}>Indent Date:</label>
                                    <input
                                        type="date"
                                        name={formMode === 'create' ? 'Indent_dt' : 'Indent_Header_Dt'}
                                        value={formMode === 'create'
                                            ? new Date().toISOString().split('T')[0]  // Show current date if in 'create' mode
                                            : (formData.Indent_Header_Dt
                                                ? new Date(formData.Indent_Header_Dt).toLocaleDateString('en-CA')  // Format to 'yyyy-mm-dd' (ISO format)
                                                : new Date().toISOString().split('T')[0]  // Fallback to current date
                                            )}
                                        onChange={handleInputChange}
                                        style={{
                                            border: 'none',
                                            borderLeft: '2px solid #D1D5DB',
                                            padding: '4px',
                                            margin: '4px 0',
                                            width: '20%',
                                            outline: 'none',
                                            backgroundColor: 'transparent'
                                        }}
                                        disabled={formMode === 'view' || formMode === 'approve'} // Disable in view and approve modes
                                    />
                                </div>




                            </div>

                            <div style={{ overflowY: 'auto', maxHeight: '250px' }}>
                                <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Sl No.</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Req. Dt.</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier Name</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier ID</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent Qty</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Remarks</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved Qty</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved by</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Short close</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                                <td style={{ border: '1px solid black' }}><div style={{ display: 'flex', width: '100%' }}>
                                                    {/* <div style={{ flex: '0 0 60%' }}><input type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', color: 'darkblue', }}
                                                        value={row.itemCd || ''} onChange={(e) => handleItemSearch(e.target.value, index)} onFocus={() => row.showSelect = true} disabled={formMode === 'view'} />
                                                    </div> */}
                                                    <div style={{ flex: '0 0 100%' }}>  <select style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', color: 'darkblue', }}
                                                        value={row.itemCd} onChange={(e) => handleItemSelects(e.target.value, index)} onBlur={() => row.showSelect = false} disabled={formMode === 'view'}>
                                                        <option value="">Select</option>
                                                        {items.map((item) => (<option key={item.itemCd} value={item.itemCd}>{item.itemCd}</option>))}</select>
                                                    </div>
                                                </div>
                                                </td>
                                                <td style={{ border: '1px solid black' }}><input type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} value={row.itemDesc || ''} onChange={(e) => handleInputChange(e, index)} readOnly disabled={formMode === 'view'} /></td>
                                                <td style={{ border: '1px solid black' }}><input type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} value={row.itemUOM || ''} onChange={(e) => handleInputChange(e, index)} readOnly disabled={formMode === 'view'} /></td>
                                                {/* <td style={{ border: '1px solid black' }}><input type="date" style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent', }} name="Req_dt" value={row.Req_dt || new Date().toISOString().split('T')[0]} onChange={(e) => handleInputChange(e, index)} disabled={formMode === 'view'} /></td> */}
                                                {/* <td style={{ border: '1px solid black' }}>
                                                    <input
                                                        type="date"
                                                        style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }}
                                                        name="Req_dt"
                                                        value={row.Req_dt ? row.Req_dt.split('T')[0] : new Date().toISOString().split('T')[0]}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        disabled={formMode === 'view'}
                                                    />
                                                </td> */}
                                                <td style={{ border: '1px solid black' }}>
                                                    <input
                                                        type="date"
                                                        style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }}
                                                        name="Req_dt"
                                                        value={row.Req_dt
                                                            ? new Date(row.Req_dt).toLocaleDateString('en-CA')  // Format date to 'yyyy-mm-dd'
                                                            : new Date().toISOString().split('T')[0]  // Fallback to current date if Req_dt is not available
                                                        }
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        disabled={formMode === 'view'}  // Disable in view mode
                                                    />
                                                </td>

                                                <td style={{ border: '1px solid black' }}><select style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', color: 'darkblue', }} value={row.Supp_id} onChange={(e) => handleSupplierSelect(e.target.value, index)} disabled={formMode === 'view'} >
                                                    <option value=""></option>
                                                    {suppliers.map((supplier, idx) => (<option key={supplier.id || idx} value={supplier.Supp_cd}>{supplier.Supp_Nm}</option>))}</select></td>
                                                <td style={{ border: '1px solid black' }}><input type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} value={row.Supp_id || ''} onChange={(e) => handleInputChange(e, index)} readOnly disabled={formMode === 'view'} /></td>
                                                <td style={{ border: '1px solid black' }}><input name="Qty" value={row.Qty || ''} onChange={(e) => handleQtyChange(e, index)} type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} disabled={formMode === 'view'} /></td>
                                                <td style={{ border: '1px solid black' }}><input name="Remarks" value={row.Remarks || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} disabled={formMode === 'view'} /></td>
                                                <td style={{ border: '1px solid black' }}><input name="Approved_qty" value={row.Approved_qty || ''} onChange={(e) => handleApproveQtyChange(e, index)} type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} disabled={formMode === 'view' || formMode === 'edit' || !isApproved} /></td>
                                                {/* <td style={{ border: '1px solid black' }}>
                                                    <input name="Approved_by" value={formMode === 'edit' || formMode === 'approve'
                                                        ? (row.Approved_by.Emp_Name || userName || '')
                                                        : (formMode === 'view' ? userName : '')}
                                                        onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} disabled={formMode === 'view' || !isApproved} />
                                                </td> */}
                                                {/* <td style={{ border: '1px solid black' }}>
                                                    {formMode === 'view' ? (
                                                        // In view mode, show Emp_Name (read-only)
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name ? row.Emp_Name : ''}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                                color: row.Emp_Name ? 'black' : 'red', // Optional: make it red if no approver
                                                            }}
                                                            disabled
                                                        />
                                                    ) : formMode === 'approve' ? (
                                                        // In approve mode, show userName if no approver is set, otherwise disabled
                                                        <input
                                                            name="Approved_by"
                                                            value={row.Approved_by ? `${row.Emp_Name} (Approved)` : userName || ''}  // Show userName if no approver
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                                // color: row.Emp_Name ? 'green' : 'black',  // Green if Emp_Name exists
                                                            }}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            disabled={row.Approved_by}  // Disable if there's an approver
                                                        />
                                                    ) :  formMode === 'create' ? (
                                                        // In edit mode, allow editing Emp_Name
                                                        <input
                                                        name="Approved_by"
                                                        value={''}
                                                        type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            disabled
                                                        />
                                                    ) : (
                                                        // In edit mode, allow editing Emp_Name
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            disabled
                                                        />
                                                    )}
                                                </td>
                                                 */}
                                                <td style={{ border: '1px solid black' }}>
                                                    {formMode === 'view' ? (
                                                        // In view mode, show Emp_Name (read-only)
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name ? row.Emp_Name : ''}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                                color: row.Emp_Name ? 'black' : 'red', // Optional: make it red if no approver
                                                            }}
                                                            disabled
                                                        />
                                                    ) : formMode === 'approve' ? (
                                                        // In approve mode, show userName if no approver is set, otherwise disabled
                                                        <input
                                                            name="Approved_by"
                                                            value={row.Approved_by ? `${row.Emp_Name} (Approved)` : userName || ''}  // Show userName if no approver
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            disabled={row.Approved_by}  // Disable if there's an approver
                                                        />
                                                    ) : formMode === 'create' ? (
                                                        // In create mode, set Approved_by to null and display input with no value
                                                        <input
                                                            name="Approved_by"
                                                            value={'NULL'} // Set as empty string or null for UI
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            disabled
                                                        />
                                                    ) : (
                                                        // In edit mode, allow editing Emp_Name
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            disabled
                                                        />
                                                    )}
                                                </td>

                                                {/* <td style={{ border: '1px solid black' }}>
                                                    {formMode === 'view' ? (
                                                        // In view mode, show Emp_Name (read-only)
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name ? row.Emp_Name : ''}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                                color: row.Emp_Name ? 'black' : 'red', // Optional: make it red if no approver
                                                            }}
                                                            disabled
                                                        />
                                                    ) : formMode === 'approve' ? (
                                                        // In approve mode, show userName if no approver is set, otherwise disabled
                                                        <input
                                                            name="Approved_by"
                                                            value={row.Approved_by ? `${row.Emp_Name} (Approved)` : userName || ''}  // Show userName if no approver
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            disabled={row.Approved_by}  // Disable if there's an approver
                                                        />
                                                    ) : formMode === 'create' ? (
                                                        // In create mode, show Emp_Name but do not include it in the payload
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            disabled
                                                        />
                                                    ) : (
                                                        // In edit mode, allow editing Emp_Name
                                                        <input
                                                            name="Emp_Name"
                                                            value={row.Emp_Name || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            type="text"
                                                            style={{
                                                                border: 'black',
                                                                outline: 'black',
                                                                width: '100%',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                        />
                                                    )}
                                                </td> */}


                                                <td style={{ border: '1px solid black' }}><select name="close" value={row.close || ''} onChange={(e) => handleInputChange(e, index)} style={{ border: 'black', outline: 'black', width: '100%', backgroundColor: 'transparent', }} disabled={formMode === 'view' || formMode === 'edit' || !isApproved} >
                                                    <option value="Select">Select</option>
                                                    <option value="Y">Y</option>
                                                    <option value="N">N</option>
                                                </select>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Indent;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const YourComponent = () => {
//     const [searchTerm, setSearchTerm] = useState('');
//     const [Location_id, setLocation_id] = useState(null);
//     const [indentNos, SetIndentNos] = useState('');
//     const [filteredItems, setFilteredItems] = useState([]);
//     const [rows, setRows] = useState([]);
//     const [formData, setFormData] = useState({});
//     const [dropdownVisible1, setDropdownVisible1] = useState(false);

//     // Fetch indent details based on Location_id and indentNos
//     const fetchIndentDetails = async (Location_id, indentNos) => {
//         if (!indentNos) {
//             console.error("Indent number is not selected.");
//             return;
//         }

//         try {
//             const response = await axios.get(`https://erpapi.emedha.in/pages/api/combined-indent/${Location_id}/${indentNos}`);
//             const data = response.data.data;

//             if (data && data.length > 0) {
//                 const updatedRows = [...Array(20)].map(() => ({
//                     itemCd: '', itemDesc: '', itemUOM: '', Req_dt: '', Supp_id: '', Qty: '', Remarks: '', Approved_qty: '', Approved_by: '', close: ''
//                 }));
//                 data.forEach((row, index) => {
//                     updatedRows[index] = {
//                         itemCd: row.itemCd || '',
//                         itemDesc: row.itemDesc || '',
//                         itemUOM: row.itemUOM || '',
//                         Req_dt: row.Req_dt ? row.Req_dt : new Date().toISOString().split('T')[0],
//                         Supp_id: row.Supp_id || '',
//                         Qty: row.Qty || '',
//                         Remarks: row.Remarks || '',
//                         Approved_qty: row.Approved_qty || '',
//                         Approved_by: row.Approved_by || '',
//                         close: row.Close || ''
//                     };
//                 });
//                 setRows(updatedRows);

//                 const uniqueData = data.filter((value, index, self) =>
//                     index === self.findIndex((t) => (
//                         t.Location_id === value.Location_id &&
//                         t.Indent_no === value.Indent_no &&
//                         t.itemCd === value.itemCd
//                     ))
//                 );
//                 if (uniqueData.length > 0) {
//                     const indentHeader = uniqueData[0];
//                     setFormData({
//                         Location_id: indentHeader.Location_id || '',
//                         Indent_no: indentHeader.Indent_no || '',
//                         Indent_Header_Dt: indentHeader.Indent_Header_Dt ? indentHeader.Indent_Header_Dt : new Date().toISOString().split('T')[0],
//                         Indent_Req_by: indentHeader.Indent_Req_by || '',
//                         Dept_id: indentHeader.Dept_id || ''
//                     });
//                 }
//             }
//         } catch (err) {
//             console.error('Error fetching indent details:', err);
//         }
//     };

//     // Fetch indent numbers (filteredItems) for different locations
//     const fetchIndentNos = async () => {
//         try {
//             const response = await fetch('https://erpapi.emedha.in/pages/api/sss/pur-indent');
//             const data = await response.json();
//             const transformedData = Object.keys(data).map((Location_id) => ({
//                 Location_id,
//                 indentNos: data[Location_id] || []  // Ensure there's a fallback to an empty array
//             }));
//             setFilteredItems(transformedData);
//         } catch (error) {
//             console.error('Error fetching indent numbers:', error);
//         }
//     };

//     useEffect(() => {
//         fetchIndentNos();
//     }, []);

//     // Handle location select
//     const handleLocationSelects = (Location_id) => {
//         setSearchTerm(Location_id);
//         setLocation_id(Location_id);

//         // Fetch indent numbers based on selected Location_id
//         const selectedLocation = filteredItems.find(item => item.Location_id === Location_id);
//         if (selectedLocation) {
//             SetIndentNos(selectedLocation.indentNos[0]);  // Set the first indent by default
//         } else {
//             SetIndentNos('');
//         }

//         // If Location_id and indentNos are available, fetch details
//         if (Location_id && indentNos) {
//             fetchIndentDetails(Location_id, indentNos);
//         }
//     };

//     // Handle search input change
//     const handleSearchInputChange = (e) => {
//         const value = e.target.value;
//         setSearchTerm(value);
//         setDropdownVisible1(true);
//         if (value) {
//             fetchIndentNos();
//         } else {
//             setFilteredItems([]);
//         }
//     };

//     const handleIndentSelect = (selectedIndentNo) => {
//         SetIndentNos(selectedIndentNo);
//         if (Location_id) {
//             fetchIndentDetails(Location_id, selectedIndentNo);
//         } else {
//             console.error("Location_id is undefined.");
//         }
//     };

//     return (
//         <div>
//             {/* Search by Location ID */}
//             <div>
//                 <label style={{ marginRight: '22px', color: 'darkblue' }}>Location Edit:</label>
//                 <input
//                     type="text"
//                     placeholder="Search by Location Id"
//                     value={searchTerm}
//                     onChange={handleSearchInputChange}
//                     style={{
//                         border: 'none',
//                         borderLeft: '2px solid #D1D5DB',
//                         padding: '4px',
//                         margin: '4px 0',
//                         width: '20%',
//                         outline: 'none',
//                         backgroundColor: 'transparent',
//                     }}
//                 />
//             </div>

//             {/* Select Location */}
//             <div>
//                 <label>Select Location:</label>
//                 <select
//                     value={Location_id || ''}
//                     onChange={(e) => handleLocationSelects(e.target.value)}
//                     style={{ padding: '4px', margin: '4px 0', width: '20%' }}
//                 >
//                     <option value="" disabled>Select a Location</option>
//                     {Array.isArray(filteredItems) && filteredItems.length > 0 && filteredItems.map((item) => (
//                         <option key={item.Location_id} value={item.Location_id}>
//                             {item.Location_id}
//                         </option>
//                     ))}
//                 </select>
//             </div>

//             {/* Select Indent Number */}
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//                 <label style={{ marginRight: '41px', color: 'darkblue' }}>Indent No:</label>
//                 <select
//                     value={indentNos || ''}
//                     onChange={(e) => { const selectedIndentNo = e.target.value; handleIndentSelect(selectedIndentNo); }}
//                     style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}
//                 >
//                     <option value="">Select Indent No</option>
//                     {Array.isArray(filteredItems.find(item => item.Location_id === Location_id)?.indentNos) &&
//                         filteredItems.find(item => item.Location_id === Location_id).indentNos.length > 0 ? (
//                         filteredItems.find(item => item.Location_id === Location_id).indentNos.map((Indent_no) => (
//                             <option key={Indent_no} value={Indent_no}>{Indent_no}</option>
//                         ))
//                     ) : (
//                         <option value="" disabled>No Indents Available</option>
//                     )}
//                 </select>
//             </div>
//         </div>
//     );
// };


// {rows.map((row, index) => {
//     const isDisabled = row.Approved_by; // Disable all fields if Approved_by is set
//     return (
//         <tr key={index}>
//             <td style={{ border: '1px solid black' }}>{index + 1}</td>
//             <td style={{ border: '1px solid black' }}>
//                 <select
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     value={row.itemCd}
//                     onChange={(e) => handleItemSelects(e.target.value, index)}
//                     disabled={formMode === 'view' || isDisabled}
//                 >
//                     <option value="">Select</option>
//                     {items.map((item) => (
//                         <option key={item.itemCd} value={item.itemCd}>
//                             {item.itemCd}
//                         </option>
//                     ))}
//                 </select>
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     type="text"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     value={row.itemDesc || ''}
//                     onChange={(e) => handleInputChange(e, index)}
//                     readOnly
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     type="text"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     value={row.itemUOM || ''}
//                     onChange={(e) => handleInputChange(e, index)}
//                     readOnly
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     type="date"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     name="Req_dt"
//                     value={row.Req_dt ? row.Req_dt.split('T')[0] : new Date().toISOString().split('T')[0]}
//                     onChange={(e) => handleInputChange(e, index)}
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <select
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                         color: 'darkblue',
//                     }}
//                     value={row.Supp_id}
//                     onChange={(e) => handleSupplierSelect(e.target.value, index)}
//                     disabled={formMode === 'view' || isDisabled}
//                 >
//                     <option value="">Select</option>
//                     {suppliers.map((supplier, idx) => (
//                         <option key={supplier.Supp_cd} value={supplier.Supp_cd}>
//                             {supplier.Supp_Nm}
//                         </option>
//                     ))}
//                 </select>
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     type="text"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     value={row.Supp_id || ''}
//                     onChange={(e) => handleInputChange(e, index)}
//                     readOnly
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     name="Qty"
//                     value={row.Qty || ''}
//                     onChange={(e) => handleQtyChange(e, index)}
//                     type="text"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     name="Remarks"
//                     value={row.Remarks || ''}
//                     onChange={(e) => handleInputChange(e, index)}
//                     type="text"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <input
//                     name="Approved_qty"
//                     value={row.Approved_qty || ''}
//                     onChange={(e) => handleApproveQtyChange(e, index)}
//                     type="text"
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     disabled={formMode === 'view' || isDisabled}
//                 />
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 {formMode === 'view' ? (
//                     <input
//                         name="Emp_Name"
//                         value={row.Emp_Name ? row.Emp_Name : ''}
//                         type="text"
//                         style={{
//                             border: '1px solid black',
//                             outline: 'none',
//                             width: '100%',
//                             backgroundColor: 'transparent',
//                             color: row.Emp_Name ? 'black' : 'red',
//                         }}
//                         disabled
//                     />
//                 ) : formMode === 'approve' ? (
//                     <input
//                         name="Approved_by"
//                         value={row.Approved_by ? `${row.Emp_Name} (Approved)` : userName || ''}
//                         type="text"
//                         style={{
//                             border: '1px solid black',
//                             outline: 'none',
//                             width: '100%',
//                             backgroundColor: 'transparent',
//                         }}
//                         onChange={(e) => handleInputChange(e, index)}
//                         disabled={row.Approved_by}
//                     />
//                 ) : (
//                     <input
//                         name="Emp_Name"
//                         value={row.Emp_Name || ''}
//                         onChange={(e) => handleInputChange(e, index)}
//                         type="text"
//                         style={{
//                             border: '1px solid black',
//                             outline: 'none',
//                             width: '100%',
//                             backgroundColor: 'transparent',
//                         }}
//                         disabled={formMode === 'view' || isDisabled}
//                     />
//                 )}
//             </td>
//             <td style={{ border: '1px solid black' }}>
//                 <select
//                     name="close"
//                     value={row.close || ''}
//                     onChange={(e) => handleInputChange(e, index)}
//                     style={{
//                         border: '1px solid black',
//                         outline: 'none',
//                         width: '100%',
//                         backgroundColor: 'transparent',
//                     }}
//                     disabled={formMode === 'view' || formMode === 'edit' || !isApproved || isDisabled}
//                 >
//                     <option value="Select">Select</option>
//                     <option value="Y">Y</option>
//                     <option value="N">N</option>
//                 </select>
//             </td>
//         </tr>
//     );
// })}

// export default YourComponent;