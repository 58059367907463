import React, { useEffect, useState } from 'react';
import { FaCheck, FaPlus, FaSave } from 'react-icons/fa';
import { HiOutlineRefresh } from 'react-icons/hi';
import { IoPrint } from 'react-icons/io5';
import { LuView } from 'react-icons/lu';
import { MdEdit } from 'react-icons/md';
import { RiCloseLargeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';
import axios from 'axios';


const MatirialIssues = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const id = user ? user.id : null;
    console.log(id)
    //   const [selectedPOs, setSelectedPOs] = useState([]);
    //   const [poList, setPoList] = useState([
    //     { poNo: '1', supplierCd: '101', supplierName: 'Supplier A', item: 'Item1', itemdesc: 'itemdesc1', uom: 'uom1', qty: 10 },
    //     { poNo: '1', supplierCd: '205', supplierName: 'Supplier B', item: 'Item2', itemdesc: 'itemdesc2', uom: 'uom2', qty: 20 },
    //     { poNo: '2', supplierCd: '101', supplierName: 'Supplier A', item: 'Item3', itemdesc: 'itemdesc3', uom: 'uom3', qty: 30 },
    //     { poNo: '2', supplierCd: '500', supplierName: 'Supplier C', item: 'Item4', itemdesc: 'itemdesc4', uom: 'uom4', qty: 15 },
    //   ]);
    const [supplierFilter, setSupplierFilter] = useState(null);

    const [currentDate, setCurrentDate] = useState('');


    useEffect(() => {
        const date = new Date();
        const formattedDate = date.toISOString().split('T')[0];
        setCurrentDate(formattedDate);
    }, []);

    // const handleSave = async () => {
    //     console.log('Save functionality to be implemented');
    // };

    const handlePrint = () => {
        console.log('Print functionality');
    };

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    //   const handlePOSelection = (po, checked) => {
    //     if (checked) {
    //       setSelectedPOs(prevSelectedPOs => [...prevSelectedPOs, po]);
    //     } else {
    //       setSelectedPOs(prevSelectedPOs => prevSelectedPOs.filter(selectedPo => selectedPo.poNo !== po.poNo));
    //     }
    //   };

    const handleConfirm = () => {
        console.log('Confirm MRN creation');
    };

    const handleCancel = () => {
        setSupplierFilter(null);
        toggleModal();
    };

    const handleApprove = (id) => {

    };
    const handleRefresh = () => {

    };

    const [showEditformm, setShowEditFormm] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showViewForm, setShowViewForm] = useState(false);

    const [visibleRows, setVisibleRows] = useState()
    useEffect(() => {
        const totalRows = '';
        const emptyRowsNeeded = totalRows < 4 ? 4 - totalRows : 0;
        setVisibleRows([...'', ...Array(emptyRowsNeeded).fill({})]);
    }, ['']);



    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };
    const [formMode, setFormMode] = useState('');
    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};

    const getButtonState = (permissionType) => {
        switch (formMode) {
            case 'create':
                return permissionType === 'create' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'edit':
                return permissionType === 'edit' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'view':
                return permissionType === 'view' || permissionType === 'clear' || permissionType === 'close';
            case 'approve':
                return permissionType === 'clear' || permissionType === 'view' || permissionType === 'approve' || permissionType === 'print' || permissionType === 'close';
            default:
                return false;
        }
    };


    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [items, setItems] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [employee, setEmployee] = useState(false);


    // const [formData, setFormData] = useState({
    //     itemCd: '',
    //     itemDesc: '',
    //     itemUOM: '',

    //     Dept_id: '',
    //     requiredQty: '',  // Added field for required qty
    //     remarks: '',      // Added field for remarks

    // });
    console.log(items)

    // const [hdrData, setHdrData] = useState({
    //     MR_Dt: '',
    //     Dept: '',
    //     Request_by: ''
    //   });

    //   const [dtlData, setDtlData] = useState([
    //     { Item_code: '', UOM: '', Stk_qty: '', Required_qty: '', Remarks: '' }
    //   ]);

    const [formData, setFormData] = useState({

        Dept_id: '',
        Indent_Req_by: id,
        MR_Dt: new Date().toISOString().split('T')[0],

    });

    // const [rows, setRows] = useState([
    //     { itemCd: '', itemDesc: '', itemUOM: '', requiredQty: '', remarks: '' }
    // ]);
    const [rows, setRows] = useState(
        Array.from({ length: 10 }, () => ({
            itemCd: '',
            itemDesc: '',
            itemUOM: '',
            requiredQty: '',
            remarks: '',
        }))
    );


    useEffect(() => {
        axios.get('https://erpapi.emedha.in/pages/api/api/location')
            .then((response) => {
                setLocations(response.data);
            })
            .catch((error) => {
                console.error('Error fetching main groups:', error);
            });

        axios.get('https://erpapi.emedha.in/pages/api/api/depertament')
            .then((response) => {
                setDepartments(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/suppliers')
            .then((response) => {
                setSuppliers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/itemsmasterform')
            .then((response) => {
                setItems(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/employeee')
            .then((response) => {
                setEmployee(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
    }, []);

    const handleDeptChange = (event) => {
        const selectedDeptId = event.target.value;
        setFormData({
            ...formData,
            Dept_id: selectedDeptId
        });
    };

    // const handleItemChange = (e) => {
    //     const selectedItemCd = e.target.value;
    //     const selectedItem = items.find(item => item.itemCd === parseInt(selectedItemCd));

    //     if (selectedItem) {
    //         setFormData({
    //             ...formData,
    //             itemCd: selectedItem.itemCd,
    //             itemDesc: selectedItem.itemDesc,
    //             itemUOM: selectedItem.itemUOM,
    //         });
    //     }
    // };
    const handleItemChange = (index, e) => {
        const selectedItemCd = e.target.value;
        const selectedItem = items.find(item => item.itemCd === parseInt(selectedItemCd));

        // If the item is found, update the specific row in the rows state
        if (selectedItem) {
            const newRows = [...rows]; // Create a copy of the rows array
            newRows[index] = {  // Update the row at the given index
                ...newRows[index], // Preserve existing data in the row
                itemCd: selectedItem.itemCd,
                itemDesc: selectedItem.itemDesc,
                itemUOM: selectedItem.itemUOM,
            };

            setRows(newRows); // Update the rows state with the modified row
        }
    };


    const [MR_No, setMR_No] = useState(""); // Maintain MRN_No state

    useEffect(() => {
        const fetchLatestMRN = async () => {
            try {
                const response = await fetch('https://erpapi.emedha.in/pages/api/api/get-latest-mr');
                const data = await response.json();
                const latestMRN = data.latestMRN;

                // Set MRN_No to the next available value
                setMR_No(latestMRN === 0 ? "1" : (parseInt(latestMRN) + 1).toString());
            } catch (error) {
                console.error('Error fetching latest MRN:', error);
            }
        };

        fetchLatestMRN();
    }, []);

    const handleSave = async () => {
      
        console.log('Form Data:', formData);
        console.log('Rows Data:', rows);

        const formDataToSubmit = {
            hdrData: {

                Dept_id: formData.Dept_id,
                Indent_Req_by: formData.Indent_Req_by,
                MR_Dt: formData.MR_Dt,
            },
            dtlData: rows.map(row => ({
                // Item_code: row.itemCd,
                // Item_desc: row.itemDesc,
                // UOM: row.itemUOM,
                // Required_qty: row.requiredQty,
                // Remarks: row.remarks,
                itemCd: row.itemCd,
                itemDesc: row.itemDesc,
                itemUOM: row.itemUOM,
                requiredQty: row.requiredQty,
                remarks: row.remarks,
                // Add Stk_qty if needed from the row
                Stk_qty: row.Stk_qty || 0,
            }))
        };

        try {
            const response = await axios.post('https://erpapi.emedha.in/pages/api/api/material-indent', formDataToSubmit);
            if (response.status === 201) {
                alert(`Material Indent saved successfully. MR_No: ${response.data.MR_No}`);
            }
        } catch (error) {
            console.error('Error saving material indent:', error);
            alert('There was an error saving the material indent.');
        }
    };
    const handleQtyChange = (index, e) => {
        const newRows = [...rows];
        newRows[index].requiredQty = e.target.value;
        setRows(newRows);
    };

    const handleRemarksChange = (index, e) => {
        const newRows = [...rows];
        newRows[index].remarks = e.target.value;
        setRows(newRows);
    };



    const [MRNo, setMRNo] = useState(''); // MR_No (Material Requisition No)
    const [mrNos, setMrNos] = useState([]); // Array of MR_No values
    const [hdrData, setHdrData] = useState(null); // To store header data
    const [dtlData, setDtlData] = useState([]); // To store detail data
    const [loading, setLoading] = useState(false); // For loading state
    const [error, setError] = useState(null); // To handle any errors

    // Fetch the list of MR_No values when the component mounts
    useEffect(() => {
        const fetchMrNos = async () => {
            try {
                const response = await axios.get('https://erpapi.emedha.in/pages/api/api/material-indent/mrnos');
                setMrNos(response.data.mrNos); // Populate MR_No list
            } catch (err) {
                setError('Error fetching MR_Nos');
            }
        };

        fetchMrNos();
    }, []);

    // Fetch data when MR_No changes
    useEffect(() => {
        if (!MRNo) return; // Skip fetching if MRNo is not provided

        const fetchData = async () => {
            setLoading(true); // Set loading to true
            setError(null); // Reset error state

            try {
                // Make the GET request to the backend API
                const response = await axios.get(`https://erpapi.emedha.in/pages/api/api/material-indent/${MRNo}`);
                console.log(response.data.data)
                // Set the header and detail data to state
                setHdrData(response.data.data.hdrData);
                setDtlData(response.data.data.dtlData);
            } catch (err) {
                setError('Error fetching data');
            } finally {
                setLoading(false); // Set loading to false after request completes
            }
        };

        fetchData();
    }, [MRNo]);


    console.log(hdrData)
    console.log(dtlData)


    // Handle changes for header data (like Request_by, Dept)
    const handleHdrChange = (e) => {
        const { name, value } = e.target;
        setHdrData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle changes for Item_code in detail rows
    const handleItemCodeChange = (index, e) => {
        const { value } = e.target;
        const updatedDtlData = [...dtlData];
        updatedDtlData[index].Item_code = value;
        setDtlData(updatedDtlData);
    };

    // Handle changes for Item_description in detail rows
    const handleItemDescriptionChange = (index, e) => {
        const { value } = e.target;
        const updatedDtlData = [...dtlData];
        updatedDtlData[index].Item_description = value;
        setDtlData(updatedDtlData);
    };

    // Handle changes for UOM (Unit of Measure) in detail rows
    const handleUOMChange = (index, e) => {
        const { value } = e.target;
        const updatedDtlData = [...dtlData];
        updatedDtlData[index].UOM = value;
        setDtlData(updatedDtlData);
    };

    // Handle changes for Stock Quantity in detail rows
    const handleStkQtyChange = (index, e) => {
        const { value } = e.target;
        const updatedDtlData = [...dtlData];
        updatedDtlData[index].Stk_qty = value;
        setDtlData(updatedDtlData);
    };

    // Handle changes for Required Quantity in detail rows
    const handleQtyChangee = (index, e) => {
        const { value } = e.target;
        const updatedDtlData = [...dtlData];
        updatedDtlData[index].Required_qty = value;
        setDtlData(updatedDtlData);
    };

    // Handle changes for Remarks in detail rows
    const handleRemarksChangee = (index, e) => {
        const { value } = e.target;
        const updatedDtlData = [...dtlData];
        updatedDtlData[index].Remarks = value;
        setDtlData(updatedDtlData);
    };


    const handleItemCodeChangee = (index, e) => {
        const selectedItemCode = e.target.value;
        const selectedItem = items.find(item => item.itemCd === selectedItemCode);

        if (selectedItem) {
            // Create a copy of the dtlData array and update the relevant row
            const updatedDtlData = [...dtlData];

            // Update the row at the specified index
            updatedDtlData[index] = {
                ...updatedDtlData[index], // Keep other fields intact
                Item_code: selectedItem.itemCd, // Update Item_code
                Item_description: selectedItem.itemDesc, // Automatically set Item_description
                UOM: selectedItem.itemUOM, // Automatically set UOM
            };

            // Set the updated dtlData to the state
            setDtlData(updatedDtlData);
        }
    };

    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {
      
        setLoading(true);
        setError(null);
        setSuccess(false);
    
        try {
            const response = await axios.put(`https://erpapi.emedha.in/pages/api/api/material-indent/${MRNo}`, {
                hdrData,
                dtlData
            });
    
            // Check if response status is 200 (successful)
            if (response.status === 200) {
                // Show success alert with the MR_No
                alert(`Material Indent saved successfully. MR_No: ${response.data.MR_No}`);
            } else {
                // Handle non-200 status codes if needed
                alert('Something went wrong, please try again.');
            }
    
            setSuccess(true);
            setLoading(false);
        } catch (err) {
            setError('Error updating data');
            setLoading(false);
        }
    };
    
    function formatDateToDateInput(date) {
        // Convert date string to a Date object
        const dateObj = new Date(date);
    
        // Adjust for timezone offset and convert to 'YYYY-MM-DD' format
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const day = dateObj.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
      }
    


    return (
        <div style={{ margin: '0 auto', backgroundColor: '#dbc4a9', padding: '1rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid black', borderRadius: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img src={A1} alt="item" style={{ width: "20%" }} />
                <h1 style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>MATERIAL ISSUES</h1>
                <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed",
                        opacity: userPermissions.add && userPermissions.add !== 0 && getButtonState('create') ? 1 : 0.5
                    }}>
                        <FaPlus size={30} onClick={() => {
                            if (userPermissions.add && userPermissions.add !== 0) {
                                toggleModal();
                                setShowEditFormm(false);
                                setShowCreateForm(true);
                                setShowViewForm(false);
                                setFormMode('create');
                            }
                        }} />
                    </div>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed",
                        opacity: userPermissions.edit && userPermissions.edit !== 0 && getButtonState('edit') ? 1 : 0.5
                    }}>
                        <MdEdit size={30} onClick={() => {
                            if (userPermissions.edit && userPermissions.edit !== 0) {
                                setShowEditFormm(true);
                                setShowCreateForm(false);
                                setShowViewForm(false);
                                setFormMode('edit');
                            } else {
                                alert("You do not have permission to edit.");
                            }
                        }} />
                    </div>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed",
                        opacity: userPermissions.view && userPermissions.view !== 0 && getButtonState('view') ? 1 : 0.5
                    }}>
                        <LuView size={30} onClick={() => {
                            if (userPermissions.view && userPermissions.view !== 0) {
                                setShowEditFormm(false);
                                setShowCreateForm(false);
                                setShowViewForm(true);
                                setFormMode('view');
                            } else {
                                alert("You do not have permission to view.");
                            }
                        }} />
                    </div>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? "pointer" : "not-allowed",
                        opacity: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? 1 : 0.5
                    }}>
                        <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                    </div>

                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? "pointer" : "not-allowed",
                        opacity: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? 1 : 0.5
                    }}>
                        <FaSave size={30} o onClick={() => {
                            //userPermissions.save && userPermissions.save !== 0 ? handleSave : null} />
                            if (userPermissions.save && userPermissions.save !== 0) {
                                if (formMode === 'create') {
                                    handleSave(); // If formMode is approve, use handleApprove
                                } else if (formMode === 'edit') {
                                    handleSubmit(); // If formMode is create or edit, use handleSave
                                }
                            } else {
                                alert("You do not have permission to save.");
                            }
                        }}
                        />
                    </div>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? "pointer" : "not-allowed",
                        opacity: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? 1 : 0.5
                    }}>
                        <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? handleApprove : null} />
                    </div>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? "pointer" : "not-allowed",
                        opacity: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? 1 : 0.5
                    }} title='Print'>
                        <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                    </div>
                    <div style={{
                        width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? "pointer" : "not-allowed",
                        opacity: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? 1 : 0.5
                    }} title='Exit'>
                        <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                    </div>
                </div>
            </div>
            <hr />

            {showCreateForm && (

                <>

                    <div>
                        <div className='p-1' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '36px' }}>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '51px', color: 'darkblue' }}>MR No:</label>
                                    <input
                                        readOnly
                                        value={MR_No}
                                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                                </div>
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '69px', color: 'darkblue' }}>Dept </label>
                                    <input maxLength={12} pattern="[A-Za-z0-9]+" name='type' type='text' style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                                </div> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '69px', color: 'darkblue' }}>Dept</label>
                                    <select name="Dept_id"
                                        value={formData.Dept_id || ''}
                                        onChange={handleDeptChange}
                                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} >
                                        <option value="">Select</option>
                                        {departments.map(Dept_id => (<option key={Dept_id.Dept_id} value={Dept_id.Dept_id}>{Dept_id.Dept_name}</option>))}
                                    </select>
                                </div>
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '25px', color: 'darkblue' }}>Request By</label>
                                    <input maxLength={12} pattern="[A-Za-z0-9]+" name='type' type='text' style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                                </div> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '22px', color: 'darkblue' }}>Request By:</label>
                                    {/* <input name="Indent_Req_by"
                                        value={user ? user.User_nm : ''}
                                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} /> */}
                                    <input
                                        name="Indent_Req_by"
                                        value={user ? user.User_nm : ''}
                                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '4px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}
                                        disabled={formMode === 'view'}
                                    />

                                    {/* Hidden input to submit the User's id */}
                                    <input
                                        type="hidden"
                                        name="Indent_Req_by_id"
                                        value={user ? user.User_id : ''}
                                    />
                                </div>

                            </div>

                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '40px', color: 'darkblue' }}>MR Dt:</label>
                                    <input type='date' value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '10px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                                </div>

                            </div>

                            <div>

                            </div>
                        </div>


                        <div style={{ marginTop: '120px', overflowY: 'auto', maxHeight: '240px', height: '240px' }}>
                            <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SI.No</th>

                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>STK QTY</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Required Qty</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Remarks</th>

                                    </tr>
                                </thead>
                                {/* <tbody>
                                    
                                    
                                    <td style={{ border: '1px solid black', padding: '8px' }}>1</td>
                                    <td style={{ border: '1px solid black' }}>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div style={{ flex: '0 0 100%' }}>
                                                <select
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        width: '100%',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    value={formData.itemCd || ''}
                                                    onChange={handleItemChange}
                                                    disabled={formMode === 'view'} // Disable if in 'view' mode
                                                >
                                                    <option value="">Select</option>
                                                    {items.map(item => (
                                                        <option key={item.itemCd} value={item.itemCd}>
                                                            {item.itemCd}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        {/* Display itemDesc, make it readonly if formMode is 'view' 
                                        {formMode === 'view' ? (
                                            <span>{formData.itemDesc}</span>
                                        ) : (
                                            <input
                                                type="text"
                                                value={formData.itemDesc}
                                                readOnly={formMode === 'view'}
                                                style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                            />
                                        )}
                                    </td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        {/* Display itemUOM, make it readonly if formMode is 'view' 
                                        {formMode === 'view' ? (
                                            <span>{formData.itemUOM}</span>
                                        ) : (
                                            <input
                                                type="text"
                                                value={formData.itemUOM}
                                                readOnly={formMode === 'view'}
                                                style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                            />
                                        )}
                                    </td>

                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        <input
                                            type="number"
                                            className="form-control"



                                            style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                        />
                                    </td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={formData.requiredQty}

                                            onChange={(e) => setFormData({ ...formData, requiredQty: e.target.value })}
                                            style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                        />
                                    </td>
                                    {/* "Remarks" Column 
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={formData.remarks}

                                            onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
                                            style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                        />
                                    </td>

                                    {/* ))} 

                                </tbody> */}


                                <tbody>
                                    {rows.map((row, index) => (
                                        <tr key={index}>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                                            <td style={{ border: '1px solid black' }}>
                                                <select
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        width: '100%',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    value={row.itemCd || ''}
                                                    onChange={(e) => handleItemChange(index, e)} // Pass index to handleItemChange
                                                    disabled={formMode === 'view'} // Disable if in 'view' mode
                                                >
                                                    <option value="">Select</option>
                                                    {items.map(item => (
                                                        <option key={item.itemCd} value={item.itemCd}>
                                                            {item.itemCd}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                {formMode === 'view' ? (
                                                    <span>{row.itemDesc}</span>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={row.itemDesc}
                                                        readOnly={formMode === 'view'}
                                                        style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                                    />
                                                )}
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                {formMode === 'view' ? (
                                                    <span>{row.itemUOM}</span>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={row.itemUOM}
                                                        readOnly={formMode === 'view'}
                                                        style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                                    />
                                                )}
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"



                                                    style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.requiredQty}
                                                    onChange={(e) => handleQtyChange(index, e)} // Handle Qty change
                                                    style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.remarks}
                                                    onChange={(e) => handleRemarksChange(index, e)} // Handle Remarks change
                                                    style={{ width: '100%', border: 'none', backgroundColor: 'transparent', color: 'darkblue' }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>





                            </table>
                        </div>
                    </div>

                </>

            )}

            <>
                {showEditformm && (
                    <div>
                        <div className='p-1' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '51px', color: 'darkblue' }}>MR No:</label>
                                    <select
                                        value={MRNo}
                                        onChange={(e) => setMRNo(e.target.value)}
                                        style={{
                                            border: 'none',
                                            borderLeft: '2px solid #D1D5DB',
                                            padding: '10px',
                                            width: '50%',
                                            outline: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                        placeholder="Select Material Requisition No"
                                    >
                                        <option value="">Select MR_No</option>
                                        {mrNos.map((mr) => (
                                            <option key={mr.MR_No} value={mr.MR_No}>
                                                {mr.MR_No}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {hdrData && (
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '69px', color: 'darkblue' }}>Dept</label>
                                            <select
                                                name="Dept_id"
                                                value={hdrData.Dept}
                                                onChange={handleDeptChange}
                                                style={{
                                                    border: 'none',
                                                    borderLeft: '2px solid #D1D5DB',
                                                    padding: '10px',
                                                    width: '50%',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {departments.map((Dept_id) => (
                                                    <option key={Dept_id.Dept_id} value={Dept_id.Dept_id}>
                                                        {Dept_id.Dept_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '25px', color: 'darkblue' }}>Request By</label>
                                            <input
                                                // value={hdrData.Request_by}
                                                value={
                                                    employee
                                                        .find(emp => emp.Emp_id === hdrData.Request_by)?.Emp_Name || ''
                                                }
                                                maxLength={12}
                                                pattern="[A-Za-z0-9]+"
                                                name="type"
                                                type="text"
                                              
                                                style={{
                                                    border: 'none',
                                                    borderLeft: '2px solid #D1D5DB',
                                                    padding: '10px',
                                                    width: '50%',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            {hdrData && (
                                    <>
                           
                           <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '40px', color: 'darkblue' }}>MR Dt:</label>
                                <input
                                    type="date"
                                    value={hdrData.MR_Dt ? formatDateToDateInput(hdrData.MR_Dt): ''}
                                    // onChange={(e) => setCurrentDate(e.target.value)}
                                    onChange={(e) => {
                                        const updatedMRNData = { ...hdrData, MR_Dt: e.target.value };
                                        setHdrData(updatedMRNData); // Set updated MRN_Dt
                                      }}
                                    style={{
                                        border: 'none',
                                        borderLeft: '2px solid #D1D5DB',
                                        padding: '10px',
                                        width: '50%',
                                        outline: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                />
                            </div>
                            </div>
                            <div>

                            </div>
                            </>
                                )}
                        </div>

                        <div style={{ marginTop: '120px', overflowY: 'auto', maxHeight: '240px' }}>
                            <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SI.No</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>STK QTY</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Required Qty</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(MRNo ? [...dtlData, ...new Array(10 - dtlData.length).fill({})] : new Array(10).fill({})).map((row, index) => (
                                        <tr key={index}>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                {row.MR_No || '-'}
                                            </td>
                                            <td style={{ border: '1px solid black' }}>
                                                <select
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        width: '100%',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    value={row.Item_code || ''}
                                                    onChange={(e) => handleItemCodeChangee(index, e)}
                                                >
                                                    <option value="">Select</option>
                                                    {items.map((item) => (
                                                        <option key={item.itemCd} value={item.itemCd}>
                                                            {item.itemCd}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="text"
                                                    value={row.Item_description || ''} // Automatically populated based on selected Item Code
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    readOnly // You can choose to make this read-only if you want to prevent manual editing
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="text"
                                                    value={row.UOM || ''} // Automatically populated based on selected Item Code
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    readOnly // You can choose to make this read-only if you want to prevent manual editing
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.Stk_qty || 0}
                                                    readOnly
                                                    onChange={(e) => handleStkQtyChange(index, e)} // Add onChange handler to update Stock Quantity
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />
                                            </td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.Required_qty || 0}
                                                    onChange={(e) => handleQtyChangee(index, e)} // Ensure this handler is in place
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />
                                            </td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.Remarks || ''}
                                                    onChange={(e) => handleRemarksChangee(index, e)} // Ensure this handler is in place
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

            </>

            {showViewForm && (
                <>
                    <div>
                        <div
                            className='p-1'
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '36px' }}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '51px', color: 'darkblue' }}>MR No:</label>

                                    <select
                                        value={MRNo}
                                        onChange={(e) => setMRNo(e.target.value)}
                                        style={{
                                            border: 'none',
                                            borderLeft: '2px solid #D1D5DB',
                                            padding: '10px',
                                            width: '50%',
                                            outline: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                        placeholder="Select Material Requisition No"
                                    >
                                        <option value="">Select MR_No</option>
                                        {mrNos.map((mr) => (
                                            <option key={mr.MR_No} value={mr.MR_No}>
                                                {mr.MR_No}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Conditionally render fields based on hdrData */}
                                {hdrData && (
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '69px', color: 'darkblue' }}>Dept</label>
                                            <select
                                                name="Dept_id"
                                                value={hdrData.Dept}
                                                onChange={handleDeptChange}
                                                style={{
                                                    border: 'none',
                                                    borderLeft: '2px solid #D1D5DB',
                                                    padding: '10px',
                                                    width: '50%',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {departments.map((Dept_id) => (
                                                    <option key={Dept_id.Dept_id} value={Dept_id.Dept_id}>
                                                        {Dept_id.Dept_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '22px', color: 'darkblue' }}>Request By:</label>

                                            <input
                                                name="Indent_Req_by"
                                                //value={hdrData.Request_by}
                                                value={
                                                    employee
                                                        .find(emp => emp.Emp_id === hdrData.Request_by)?.Emp_Name || ''
                                                }
                                                style={{
                                                    border: 'none',
                                                    borderLeft: '2px solid #D1D5DB',
                                                    padding: '4px',
                                                    margin: '4px 0',
                                                    width: '20%',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                }}
                                                disabled={formMode === 'view'}
                                            />

                                            {/* Hidden input to submit the User's id */}
                                            <input
                                                type="hidden"
                                                name="Indent_Req_by_id"
                                                value={user ? user.User_id : ''}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            {hdrData && (
                                    <>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '40px', color: 'darkblue' }}>MR Dt:</label>
                                    <input
                                         type="date"
                                        value={hdrData.MR_Dt ? formatDateToDateInput(hdrData.MR_Dt): ''}
                                       readOnly
                                        style={{
                                            border: 'none',
                                            borderLeft: '2px solid #D1D5DB',
                                            padding: '10px',
                                            width: '50%',
                                            outline: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                    />
                                </div>
                            </div>

                            <div></div>
                            </>
                                )}
                        </div>

                        {/* Display the table with 10 empty rows by default */}
                        <div
                            style={{
                                marginTop: '120px',
                                overflowY: 'auto',
                                maxHeight: '240px',
                                height: '240px',
                            }}
                        >
                            <table
                                style={{
                                    minWidth: '100%',
                                    borderCollapse: 'collapse',
                                    border: '1px solid black',
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            SI.No
                                        </th>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            Item Code
                                        </th>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            Item Description
                                        </th>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            UOM
                                        </th>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            STK QTY
                                        </th>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            Required Qty
                                        </th>
                                        <th
                                            style={{
                                                border: '1px solid black',
                                                textAlign: 'center',
                                                color: 'darkblue',
                                            }}
                                        >
                                            Remarks
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {/* If MRNo is selected, display dtlData or fill with empty rows if dtlData has less than 10 */}
                                    {(MRNo ? [...dtlData, ...new Array(10 - dtlData.length).fill({})] : new Array(10).fill({})).map((row, index) => (
                                        <tr key={index}>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                {row.MR_No || '-'}
                                            </td>
                                            <td style={{ border: '1px solid black' }}>
                                                <select
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        width: '100%',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    value={row.Item_code || ''}
                                                    disabled={formMode === 'view'}
                                                >
                                                    <option value="">Select</option>
                                                    {items.map((item) => (
                                                        <option key={item.itemCd} value={item.itemCd}>
                                                            {item.itemCd}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                {formMode === 'view' ? (
                                                    <span>{row.Item_description || '-'}</span>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={row.Item_description || ''}
                                                        readOnly={formMode === 'view'}
                                                        style={{
                                                            width: '100%',
                                                            border: 'none',
                                                            backgroundColor: 'transparent',
                                                            color: 'darkblue',
                                                        }}
                                                    />
                                                )}
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>

                                                <input
                                                    type="text"
                                                    value={row.UOM || ''}
                                                    readOnly={formMode === 'view'}
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />

                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.Stk_qty || 0}
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.Required_qty || 0}
                                                    onChange={(e) => handleQtyChange(index, e)}
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.Remarks || ''}
                                                    onChange={(e) => handleRemarksChange(index, e)}
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
};

export default MatirialIssues;



// {showViewForm && (
//     <>
//         <div>
//             <div
//                 className='p-1'
//                 style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '36px' }}
//             >
//                 <div>
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                         <label style={{ marginRight: '51px', color: 'darkblue' }}>MR No:</label>

//                         <select
//                             value={MRNo}
//                             onChange={(e) => setMRNo(e.target.value)}
//                             style={{
//                                 border: 'none',
//                                 borderLeft: '2px solid #D1D5DB',
//                                 padding: '10px',
//                                 width: '50%',
//                                 outline: 'none',
//                                 backgroundColor: 'transparent',
//                             }}
//                             placeholder="Select Material Requisition No"
//                         >
//                             <option value="">Select MR_No</option>
//                             {mrNos.map((mr) => (
//                                 <option key={mr.MR_No} value={mr.MR_No}>
//                                     {mr.MR_No}
//                                 </option>
//                             ))}
//                         </select>
//                     </div>

//                     {/* Conditionally render fields based on hdrData */}
//                     {hdrData && (
//                         <>
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <label style={{ marginRight: '69px', color: 'darkblue' }}>Dept</label>
//                                 <select
//                                     name="Dept_id"
//                                     value={hdrData.Dept}
//                                     onChange={handleDeptChange}
//                                     style={{
//                                         border: 'none',
//                                         borderLeft: '2px solid #D1D5DB',
//                                         padding: '10px',
//                                         width: '50%',
//                                         outline: 'none',
//                                         backgroundColor: 'transparent',
//                                     }}
//                                 >
//                                     <option value="">Select</option>
//                                     {departments.map((Dept_id) => (
//                                         <option key={Dept_id.Dept_id} value={Dept_id.Dept_id}>
//                                             {Dept_id.Dept_name}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>

//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <label style={{ marginRight: '22px', color: 'darkblue' }}>Request By:</label>

//                                 <input
//                                     name="Indent_Req_by"
//                                     value={hdrData.Request_by}
//                                     style={{
//                                         border: 'none',
//                                         borderLeft: '2px solid #D1D5DB',
//                                         padding: '4px',
//                                         margin: '4px 0',
//                                         width: '20%',
//                                         outline: 'none',
//                                         backgroundColor: 'transparent',
//                                     }}
//                                     disabled={formMode === 'view'}
//                                 />

//                                 {/* Hidden input to submit the User's id */}
//                                 <input
//                                     type="hidden"
//                                     name="Indent_Req_by_id"
//                                     value={user ? user.User_id : ''}
//                                 />
//                             </div>
//                         </>
//                     )}
//                 </div>

//                 <div>
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                         <label style={{ marginRight: '40px', color: 'darkblue' }}>MR Dt:</label>
//                         <input
//                             type="date"
//                             value={currentDate}
//                             onChange={(e) => setCurrentDate(e.target.value)}
//                             style={{
//                                 border: 'none',
//                                 borderLeft: '2px solid #D1D5DB',
//                                 padding: '10px',
//                                 width: '50%',
//                                 outline: 'none',
//                                 backgroundColor: 'transparent',
//                             }}
//                         />
//                     </div>
//                 </div>

//                 <div></div>
//             </div>

//             {dtlData.length > 0 && (
//                 <div
//                     style={{
//                         marginTop: '120px',
//                         overflowY: 'auto',
//                         maxHeight: '240px',
//                         height: '240px',
//                     }}
//                 >
//                     <table
//                         style={{
//                             minWidth: '100%',
//                             borderCollapse: 'collapse',
//                             border: '1px solid black',
//                         }}
//                     >
//                         <thead>
//                             <tr>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     SI.No
//                                 </th>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     Item Code
//                                 </th>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     Item Description
//                                 </th>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     UOM
//                                 </th>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     STK QTY
//                                 </th>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     Required Qty
//                                 </th>
//                                 <th
//                                     style={{
//                                         border: '1px solid black',
//                                         textAlign: 'center',
//                                         color: 'darkblue',
//                                     }}
//                                 >
//                                     Remarks
//                                 </th>
//                             </tr>
//                         </thead>

//                         <tbody>
//                             {dtlData.map((row, index) => (
//                                 <tr key={index}>
//                                     <td style={{ border: '1px solid black', padding: '8px' }}>
//                                         {row.MR_No}
//                                     </td>
//                                     <td style={{ border: '1px solid black' }}>
//                                         <select
//                                             style={{
//                                                 border: 'black',
//                                                 outline: 'black',
//                                                 width: '100%',
//                                                 backgroundColor: 'transparent',
//                                                 color: 'darkblue',
//                                             }}
//                                             value={row.Item_code}
//                                             disabled={formMode === 'view'}
//                                         >
//                                             <option value="">Select</option>
//                                             {items.map((item) => (
//                                                 <option key={item.itemCd} value={item.itemCd}>
//                                                     {item.itemCd}
//                                                 </option>
//                                             ))}
//                                         </select>
//                                     </td>

//                                     <td style={{ border: '1px solid black', padding: '8px' }}>
//                                         {formMode === 'view' ? (
//                                             <span>{row.Item_description}</span>
//                                         ) : (
//                                             <input
//                                                 type="text"
//                                                 value={row.Item_description}
//                                                 readOnly={formMode === 'view'}
//                                                 style={{
//                                                     width: '100%',
//                                                     border: 'none',
//                                                     backgroundColor: 'transparent',
//                                                     color: 'darkblue',
//                                                 }}
//                                             />
//                                         )}
//                                     </td>

//                                     <td style={{ border: '1px solid black', padding: '8px' }}>
//                                         {formMode === 'view' ? (
//                                             <span>{row.UOM}</span>
//                                         ) : (
//                                             <input
//                                                 type="text"
//                                                 value={row.itemUOM}
//                                                 readOnly={formMode === 'view'}
//                                                 style={{
//                                                     width: '100%',
//                                                     border: 'none',
//                                                     backgroundColor: 'transparent',
//                                                     color: 'darkblue',
//                                                 }}
//                                             />
//                                         )}
//                                     </td>
//                                     <td style={{ border: '1px solid black', padding: '8px' }}>
//                                         <input
//                                             type="number"
//                                             className="form-control"
//                                             value={row.Stk_qty}
//                                             style={{
//                                                 width: '100%',
//                                                 border: 'none',
//                                                 backgroundColor: 'transparent',
//                                                 color: 'darkblue',
//                                             }}
//                                         />
//                                     </td>

//                                     <td style={{ border: '1px solid black', padding: '8px' }}>
//                                         <input
//                                             type="number"
//                                             className="form-control"
//                                             value={row.Required_qty}
//                                             onChange={(e) => handleQtyChange(index, e)}
//                                             style={{
//                                                 width: '100%',
//                                                 border: 'none',
//                                                 backgroundColor: 'transparent',
//                                                 color: 'darkblue',
//                                             }}
//                                         />
//                                     </td>

//                                     <td style={{ border: '1px solid black', padding: '8px' }}>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             value={row.Remarks}
//                                             onChange={(e) => handleRemarksChange(index, e)}
//                                             style={{
//                                                 width: '100%',
//                                                 border: 'none',
//                                                 backgroundColor: 'transparent',
//                                                 color: 'darkblue',
//                                             }}
//                                         />
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             )}
//         </div>
//     </>
// )}
