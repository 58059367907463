import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { LuView } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaSave, FaSearch } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import { RiCloseLargeFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import A1 from '../Images/logo-w.png';
import axios from 'axios';

const ItemMaster = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', blockUnblock: '', accountCode: '', accountCodee: '' });
    const [showForm, setShowForm] = useState(false);
    const [items, setItems] = useState([]);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const user_Id = user ? user.id : null;
    // const userName = user ? user.User_nm : 'Guest';
    useEffect(() => {
        fetchItems();
    }, [user_Id]);

    const fetchItems = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/itemsmasterform');
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const [mainGroups, setMainGroups] = useState([]);
    const [subGroups, setSubGroups] = useState([]);

    useEffect(() => {
        axios.get('https://erpapi.emedha.in/pages/api/api/maingrp')
            .then((response) => {
                setMainGroups(response.data);
            })
            .catch((error) => {
                console.error('Error fetching main groups:', error);
            });
        axios.get('https://erpapi.emedha.in/pages/api/api/subgrp')
            .then((response) => {
                setSubGroups(response.data);
            })
            .catch((error) => {
                console.error('Error fetching sub groups:', error);
            });
    }, []);
    
    const selectedDescription = mainGroups.find(group => group.Main_Grp === parseInt(formData.Main_Grp))?.Main_Grp_Desc || '';
    const selectedSubDescription = subGroups.find(group => group.Sub_Grp === parseInt(formData.Sub_Grp))?.Sub_Grp_Desc || '';
  
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const fetchItemss = async (searchTerm = '') => {
        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/api/itemsmasterform?searchTerm=${searchTerm}`);
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setFilteredItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    useEffect(() => {
        if (searchTerm) {
            fetchItemss(searchTerm);
        } else {
            setFilteredItems([]);
        }
    }, [searchTerm]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);



    const handleSaveItem = async () => {
        if (!formData.itemUOM || !formData.origin) {
            alert('ItemDesc, and HSNCode are required!');
            return;
        }
        const form = new FormData();

        // Update form.append with the new keys
        form.append('itemDesc', formData.itemDesc);
        form.append('hsnCode', formData.hsnCode);
        form.append('taxRate', formData.taxRate);
        form.append('closeFlag', formData.closeFlag);
        form.append('itc', formData.itc);
        form.append('itemUOM', formData.itemUOM);
        form.append('itemLocation', formData.itemLocation);
        form.append('origin', formData.origin);
        form.append('cth', formData.cth);
        form.append('minQty', formData.minQty);
        form.append('Main_Grp', formData.Main_Grp);
        form.append('Main_Grp_Desc', formData.Main_Grp_Desc);
        form.append('grade', formData.grade);
        form.append('validity', formData.validity);
        form.append('maxQty', formData.maxQty);
        form.append('Sub_Grp', formData.Sub_Grp);
        form.append('Sub_Grp_Desc', formData.Sub_Grp_Desc);
        form.append('abc', formData.abc);
        form.append('ved', formData.ved);
        form.append('reOrderQty', formData.reOrderQty);
        form.append('make', formData.make);
        form.append('prodCode', formData.prodCode);
        form.append('department', formData.department);
        form.append('department1', formData.department1);
        form.append('blockUnblock', formData.blockUnblock);
        form.append('accountCode', formData.accountCode);
        form.append('accountCodee', formData.accountCodee);

        // Append image if it exists
        if (formData.image) {
            form.append('image', formData.image);
        }

        try {
            if (isEditing) {
                await axios.put(`https://erpapi.emedha.in/pages/api/api/itemsss-mstt/${editId}`, form);
                setItems(items.map(item => (item.itemCd === editId ? { ...formData, itemCd: editId } : item)));
                alert('Item updated successfully!');
            } else {
                const response = await axios.post('https://erpapi.emedha.in/pages/api/api/itemsss-mst', form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setItems([...items, { ...formData, id: response.data.itemId }]);
                alert('Item added successfully!');
            }
        } catch (error) {
            alert('Item added successfully!');
            console.error('Error saving item:', error);
        }
    };

    const [dropdownVisible1, setDropdownVisible1] = useState(false)

    const handleEditItem = (item) => {
        setSearchTerm(item.itemCd);
        setFormData({ itemDesc: item.itemDesc, hsnCode: item.hsnCode, taxRate: item.taxRate, closeFlag: item.closeFlag, itc: item.itc, image: item.image, itemUOM: item.itemUOM, itemLocation: item.itemLocation, origin: item.origin, cth: item.cth, minQty: item.minQty, Main_Grp: item.Main_Grp, Main_Grp_Desc: item.Main_Grp_Desc, grade: item.grade, validity: item.validity, maxQty: item.maxQty, Sub_Grp: item.Sub_Grp, Sub_Grp_Desc: item.Sub_Grp_Desc, abc: item.abc, ved: item.ved, reOrderQty: item.reOrderQty, make: item.make, prodCode: item.prodCode, department: item.department, department1: item.department1, blockUnblock: item.blockUnblock, accountCode: item.accountCode, accountCodee: item.accountCodee });
        setEditId(item.itemCd);
        setIsEditing(true);
        setShowForm(true);
        setDropdownVisible1(false);
        setShowSearchh(false);
    };

    const [showSearch, setShowSearch] = useState(false);
    const [showSearchh, setShowSearchh] = useState(false);
    const handleBack = () => {
        navigate(-1);
    };
    const latestItem = items.reduce((max, item) => (item.itemCd > max.itemCd ? item : max), { itemCd: 0 });

    const toggleForm = () => {
        setShowForm(prevState => !prevState);
        setFormData({ itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', blockUnblock: '', accountCode: '', accountCodee: '' });
        setIsEditing(false);
        setEditId(null);
        setShowForm(true);
        setShowSearch(false);
        setShowSearchh(true);

    };

    const handleRefresh = () => {
        setFormData({
            itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', blockUnblock: '', accountCode: '', accountCodee: ''
        });
        setSelectedItem({
            itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', blockUnblock: '', accountCode: '', accountCodee: ''
        });
    };

    const handleViewItem = (item) => {
        setFormMode('view');
        setSelectedItem(item);
        setShowForm(true);
        setDropdownVisible1(false);
        setShowSearchh(false);
        setShowSearch(true);
    };

    const handleApprove = (id) => {
        if (id) {
            axios
                .put(`https://erpapi.emedha.in/pages/api/api/itemsss-mst/approve/${id}`)
                .then((response) => {
                    console.log('Item approved successfully:', response.data);
                    setItems((prevItems) =>
                        prevItems.map((item) =>
                            item.itemCd === id ? { ...item, isApproved: true } : item
                        )
                    );
                    alert('Item approved successfully');
                })
                .catch((error) => {
                    console.error('Error approving item:', error);
                    alert(error.response?.data?.message || 'Failed to approve item');
                });

        } else {
            alert("Item ID is invalid.");
        }
    };


    const handlePrint = () => {
        const printContent = document.getElementById("print-content");
        if (printContent) {
            const originalContent = document.body.innerHTML;
            document.body.innerHTML = printContent.innerHTML;
            window.print();
            document.body.innerHTML = originalContent;
        } else {
            alert("Printer content not found!");
        }
    };

    const handleInputChangee = (e) => {
        let value = e.target.value;
        if (/^\d{0,5}$/.test(value)) {
            setFormData({
                ...formData,
                taxRate: value
            });
        }
    };
    const handleInputChangge = (e) => {
        let value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setFormData({
                ...formData,
                hsnCode: value
            });
        }
    };
    const handleInputChanggee = (e) => {
        let value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setFormData({
                ...formData,
                minQty: value
            });
        }
    };
    const handleInputChanggeee = (e) => {
        let value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setFormData({
                ...formData,
                maxQty: value
            });
        }
    };
    const handleInputChangggeee = (e) => {
        let value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setFormData({
                ...formData,
                reOrderQty: value
            });
        }
    };
    const handleInputChangggaeee = (e) => {
        let value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {
            setFormData({
                ...formData,
                accountCode: value
            });
        }
    };

    const handleMakeInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 20) {
            setFormData({
                ...formData,
                make: value
            });
        }
    };
    const handleProdCodeInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 25) {
            setFormData({
                ...formData,
                prodCode: value
            });
        }
    };
    const handleProdItemLocationInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 50) {
            setFormData({
                ...formData,
                itemLocation: value
            });
        }
    };
    const handleProdItemDescInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 30) {
            setFormData({
                ...formData,
                itemDesc: value
            });
        }
    };
    const handleProdCthInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]{0,10}$/.test(value)) {
            setFormData({
                ...formData,
                cth: value
            });
        }
    };



    const [formMode, setFormMode] = useState('');

    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};

    const getButtonState = (permissionType) => {
        switch (formMode) {
            case 'create':
                return permissionType === 'create' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'edit':
                return permissionType === 'edit' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'view':
                return permissionType === 'view' || permissionType === 'clear' || permissionType === 'close';
            case 'approve':
                return permissionType === 'clear' || permissionType === 'view' || permissionType === 'approve' || permissionType === 'print' || permissionType === 'close';
            default:
                return false;
        }
    };


    return (
        <>
            <div style={{ backgroundColor: "#dbc4a9", padding: "1rem", margin: "0 auto", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", border: '1px solid black', borderRadius: '10px' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>
                        ITEM MASTER
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.add && userPermissions.add !== 0 && getButtonState('create') ? 1 : 0.5
                        }}>
                            <FaPlus size={30} onClick={() => {
                                if (userPermissions.add && userPermissions.add !== 0) { toggleForm(); setShowSearch(false); setFormMode('create'); }
                            }} />
                        </div>

                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.edit && userPermissions.edit !== 0 && getButtonState('edit') ? 1 : 0.5
                        }}>
                            <MdEdit size={30} onClick={() => {
                                if (userPermissions.edit && userPermissions.edit !== 0) { setShowForm(true); setFormMode('edit'); setShowSearch(true); setShowSearchh(false); }
                            }} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.view && userPermissions.view !== 0 && getButtonState('view') ? 1 : 0.5
                        }}>
                            <LuView size={30} onClick={userPermissions.view && userPermissions.view !== 0 ? handleViewItem : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? "pointer" : "not-allowed",
                            opacity: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? 1 : 0.5
                        }}>
                            <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? "pointer" : "not-allowed",
                            opacity: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? 1 : 0.5
                        }}>
                            <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSaveItem : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? "pointer" : "not-allowed",
                            opacity: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? 1 : 0.5
                        }}>
                            <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? () => handleApprove(selectedItem.itemCd) : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? "pointer" : "not-allowed",
                            opacity: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? 1 : 0.5
                        }}>
                            <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? "pointer" : "not-allowed",
                            opacity: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? 1 : 0.5
                        }}>
                            <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                        </div>
                    </div>
                </div>
                {/* <p>Welcome To Item Master: {userName}</p> */}
                <hr />
                {showForm && (
                    <>
                        <div className="container">
                            {showSearch && (
                                <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', position: 'relative', paddingLeft: '46px' }}>
                                    <label className="me-4 mb-0" style={{ color: "darkblue", fontWeight: 'bold' }}><FaSearch /></label>
                                    <input type="text" placeholder="Search by Item CD" value={searchTerm}
                                        onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                        style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} />
                                    {filteredItems.length > 0 && dropdownVisible1 && (
                                        <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                            {filteredItems.map((item) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => handleEditItem(item)}
                                                    style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                    {item.itemCd}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}

                            {showSearchh && (
                                <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', position: 'relative', paddingLeft: '6px' }}>
                                    <label className="me-4 mb-0" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemCd</label>
                                    <input type="text" placeholder="Item CD" value={latestItem ? latestItem.itemCd : ''} style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} />
                                </div>
                            )}

                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemDesc</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemDesc" value={formData.itemDesc || ''} onChange={handleProdItemDescInputChange} maxLength={30} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ width: '20%' }}>
                                    <button style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem", backgroundColor: "#edf2f7" }}>
                                        Barcode Print
                                    </button>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '15%' }}>
                                    {formMode === 'view' ? (
                                        <img src={selectedItem.image || ''} alt="item" style={{ width: "30px", height: "30px", objectFit: "cover", marginTop: "10px" }} />
                                    ) : (
                                        <input type="file" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="image" onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })} disabled={formMode === 'view'} />
                                    )}
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '35%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>HSNCode</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="hsnCode" value={formData.hsnCode || ''} onChange={handleInputChangge} disabled={formMode === 'view'} />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '20%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>TaxRate</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="taxRate" value={formData.taxRate || ''} onChange={handleInputChangee} disabled={formMode === 'view'} />
                                    <span style={{ position: 'absolute', right: '45%', transform: 'translateY(-50%)', color: 'darkblue', marginTop: '20px' }}>%</span>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '10%', paddingLeft: '200px' }}>
                                    <input type="checkbox" style={{ width: "30px", height: "15px", border: "1px solid #e2e8f0", padding: "0.25rem", backgroundColor: "#edf2f7" }} name="closeFlag" value={formData.closeFlag || ''} onChange={handleInputChange} disabled={formMode === 'view'} />
                                    <label style={{ color: "darkblue", fontWeight: 'bold', marginLeft: '0.5rem' }}>CloseFlag</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', paddingLeft: '16px' }}>
                                <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>ITC</label>
                                <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itc" value={formData.itc} onChange={handleInputChange} disabled={formMode === 'view'}>
                                    <option value="N">N</option>
                                    <option value="Y">Y</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-2 mb-1 me-5" style={{ marginBottom: "1rem" }}>
                            <label style={{ display: "block", color: "darkblue", fontWeight: 'bold', }}>ITEM CHARACTERSTICS</label>
                        </div>
                        <div style={{ border: "1px solid #e2e8f0", padding: "1rem" }}>
                            <div className="mt-0" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '31%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>ITEMUOM</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemUOM" value={formData.itemUOM} onChange={handleInputChange} required disabled={formMode === 'view'}>
                                        <option value="Select">Select</option>
                                        <option value="KG">KG</option>
                                        <option value="TON">TON</option>
                                        <option value="LTRS">LTRS</option>
                                        <option value="PCS">PCS</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '62%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemLocation</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemLocation" value={formData.itemLocation} onChange={handleProdItemLocationInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>ORGIN</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="origin" value={formData.origin} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="Select">Select</option>
                                        <option>Local</option>
                                        <option>international</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>CTH</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="cth" value={formData.cth} onChange={handleProdCthInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '26%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MinQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="minQty" value={formData.minQty} onChange={handleInputChanggee} disabled={formMode === 'view'} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '31%', paddingLeft: '9px' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MAKE</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="make" value={formData.make} onChange={handleMakeInputChange} maxLength={20} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', paddingLeft: '21px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ProdCode</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="prodCode" value={formData.prodCode} onChange={handleProdCodeInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>GRADE</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="grade" value={formData.grade} onChange={handleInputChange} required disabled={formMode === 'view'}>
                                        <option value="Select">Select</option>
                                        <option> A</option>
                                        <option> B</option>
                                        <option> C</option>
                                        <option> D</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label style={{ color: "darkblue", fontWeight: 'bold' }}>Validity</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="validity" value={formData.validity} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="Select">Select</option>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '26%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MaxQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="maxQty" value={formData.maxQty} onChange={handleInputChanggeee} disabled={formMode === 'view'} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '31%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>MainGroup</label>
                                    <select type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Main_Grp" value={formData.Main_Grp} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="">Select Main Group</option>
                                        {mainGroups.map((group) => (
                                            <option key={group.Sub_Grp} value={group.Main_Grp}>
                                                {group.Main_Grp}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                               
                                <input type="text" style={{ width: "60%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Main_Grp_Desc" value={selectedDescription} onChange={handleInputChange} disabled={formMode === 'view'}/>
                              
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>ABC</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="abc" value={formData.abc} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="Select">Select</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>VED</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="ved" value={formData.ved} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="Select">Select</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ReOrderQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="reOrderQty" value={formData.reOrderQty} onChange={handleInputChangggeee} disabled={formMode === 'view'} />
                                </div>
                            </div>
                            <div className="mt-3 mb-1" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '33%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>SubGroup</label>
                                    <select type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Sub_Grp" value={formData.Sub_Grp} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="">Select Sub Group</option>
                                        {subGroups.map((group) => (
                                            <option key={group.Sub_Grp} value={group.Sub_Grp}>
                                                {group.Sub_Grp}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <input type="text" style={{ width: "64%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Sub_Grp_Desc" value={selectedSubDescription} onChange={handleInputChange} disabled={formMode === 'view'}/>
                               
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Department</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="department" value={formData.department} onChange={handleInputChange} disabled={formMode === 'view'} />
                                </div>
                                <input type="text" style={{ width: "50%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="department1" value={formData.department1} onChange={handleInputChange} disabled={formMode === 'view'} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1, borderTop: '1px solid black', marginRight: '10px', }}></div>
                                <span style={{ padding: '0 10px', fontSize: '25px' }}>Accounts</span>
                                <div style={{ flexGrow: 1, borderTop: '1px solid black', marginLeft: '10px', }}></div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Block / Unblock</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="blockUnblock" value={formData.blockUnblock || ''} onChange={handleInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', paddingLeft: '500px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>A/c Code</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="accountCode" value={formData.accountCode} onChange={handleInputChangggaeee} disabled={formMode === 'view'} />
                                </div>
                                <input type="text" style={{ width: "40%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="accountCodee" value={formData.accountCodee} onChange={handleInputChange} disabled={formMode === 'view'} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ItemMaster;

